import React, { useState, createRef } from 'react';
import DataTable from 'react-data-table-component';
import Axios from 'axios';
import { formatDate } from '../../utils/util';
import SpinnerComponent from '../common/SpinnerComponent';

export default function StudentInfo({ location: { pathname }, history , selectedUser, updateStudent}) {
    const [showCustomMessage, setShowCustomMessage] = useState(false);
    const [tglCourse, settglCourse] = useState(null);
    const [showLoader, setshowLoader] = useState(false);
    const messageRef = createRef();
    const amountRef = createRef();
    var [courses, setCourses] = useState(
        selectedUser.coursesInfo.filter(
            course => selectedUser.userInfo.courses.includes(course.courseId) || selectedUser.userInfo.courseHistory?.find(cours => cours.courseId === course.courseId)
        )
        .map(course1 => {
            return {
                ...course1,
                active: !selectedUser.userInfo.courseHistory?.find(course => course.courseId === course1.courseId)
            }
        })
    );
    const columns = [
        {
            name: 'Course Name',
            selector: 'title',
            sortable: true
        },
        {
            name: 'End Date',
            cell: (row) => formatDate(row.endDate)
        },
        {
            name: 'State',
            selector: 'active',
            cell: (row) => <span className={"badge "+(row.active? "badge-success": "badge-danger")}>{row.active? 'Activated': 'Deactivated'}</span>
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <button title={row.active? 'Deactivate': 'Activate'} className={"btn btn-circle " + (row.active? 'btn-danger': 'btn-success')} onClick={e => toggleCourseAcitvation(row)}>
                        <svg className="bi bi-power" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M5.578 4.437a5 5 0 104.922.044l.5-.866a6 6 0 11-5.908-.053l.486.875z" clipRule="evenodd"/>
                            <path fillRule="evenodd" d="M7.5 8V1h1v7h-1z" clipRule="evenodd"/>
                        </svg>
                    </button>
                </>
            )
        }
    ];

    async function toggleCourseAcitvation(row) {
        settglCourse(row);
        if(row.active) {
            setShowCustomMessage(true);
        } else {
            toggleActivation(null, false, row);
        }
    }

    async function toggleActivation(e, toDeactivate, row) {
        e && e.preventDefault();
        toDeactivate && setShowCustomMessage(false);
        setshowLoader(true);
        let id = row? row.courseId: tglCourse?.courseId;
        id = id + '';
        try {
            const obj = {
                courseId: id,
                state: toDeactivate? 'deactivate': 'renew',
                userId: selectedUser.userInfo.userId,
            }
            if(toDeactivate) {
                obj.message = messageRef.current.value;
                obj.amount = amountRef.current.value;
            }
            const res = await Axios.patch('/admin/user/subscription', obj);
            setCourses(courses.map(course => course.courseId === id? {
                ...course,
                active: !toDeactivate
            }: course));
            setshowLoader(false);
            updateStudent(selectedUser.userInfo.userId, res.data);
        } catch(error) {
            console.log(error);
            setshowLoader(false);
        }
    }
    return (
        <>
        {
            <div style={{position: 'relative'}}>
            {showLoader?<SpinnerComponent/>: ''}
            <DataTable
                title={<span style={{textTransform: 'capitalize'}}>Course Enrolled by {selectedUser.userInfo.username}</span>}
                columns={columns}
                data={courses}
                keyField="_id"
                pagination={true}
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10]}
            />
            {
                showCustomMessage? 
                <div className="modal fade show active modal-student" style={{backgroundColor: 'rgb(0,0,0, 0.65)', display: 'block'}} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Deactivate {tglCourse.title} - Subscription</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowCustomMessage(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                <div className="modal-body">
                    <form onSubmit={e => toggleActivation(e, true)}>
                        <div className="form-group">
                            <textarea className="form-control" required name="custom-note" ref={messageRef} minlenght="10" placeholder="Enter Custom Message" className="p-2" rows="3" style={{width: '100%'}}></textarea>
                        </div>
                        <div className="form-group" style={{maxWidth: '200px'}}>
                            <label htmlFor="amount" className="control-label">Amount to Pay</label>&nbsp;&nbsp;
                            <input name="amount" className="form-control" type="text" required id="amount" ref={amountRef} pattern="^[0-9]+(\.[0-9][0-9]?)?$" onChange = {(e) => e.target.setCustomValidity('')} onInvalid={(e) => e.target.setCustomValidity("Please enter valid amount")}></input>
                        </div>{/* <br/> */}
                        {/* <label htmlFor="due-date">Course Expires On</label>&nbsp;&nbsp;
                        <input name="due-date" type="date" required id="due-date"></input> */}
                        <button className="btn btn-primary">Confirm</button>
                    </form>
                </div>
                </div>
                </div>
              </div> : ''
            }
        </div>
        }
        </>
    )
}
