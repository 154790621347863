import React, { Component } from 'react';
import './css/dashboard.scss';
import {getCoursesCards} from '../utils/util'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class DashboardComponent extends Component {
   
    redirectTo(courseId) {
        if(this.props.userDetails.courses.find(course => course.courseId === courseId)?.active || 
        this.props.userDetails.courseHistory.find(course => course.courseId === courseId)?.active
        ) {
            this.props.history.push('/courses/'+courseId);
        }
    }
    render() {
        return (
            <>
            <div className="">
                <h3 className="font-weight-bold course-header text-primary">Your Courses</h3>
                <hr className="course-section"></hr>
                <div className="row w-100 dashboard-row" style={{padding: '10px'}}role="listbox">
                    {this.props.userDetails.courses && this.props.userDetails.courses.length? getCoursesCards(this.props.userDetails.courses, (courseId) => this.redirectTo(courseId), true, true): <p className="p-2">Sorry! We couldn't find any course linked to your account. Any course you buy will appear here.</p>}  
                </div>
            </div>
            {
                this.props.userDetails.courseHistory?.length? <div className="">
                    <h3 className="font-weight-bold course-header text-primary">Past Courses</h3>
                    <hr className="course-section"></hr>
                    <div className="row w-100 dashboard-row" style={{padding: '10px'}}role="listbox">
                        {getCoursesCards(this.props.userDetails.courseHistory, (courseId) => this.redirectTo(courseId), true, true)}  
                    </div>
                </div>: ''
            }
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        userDetails: state.userData
    }
}
export default withRouter(connect(mapStateToProps)(DashboardComponent));
