import React, { useReducer, useEffect, useState } from 'react'
import { checkPasswords, checkPhoneNumber } from '../utils/util'
import SpinnerComponent from './common/SpinnerComponent'
import ErrorComponent from './common/ErrorComponent'
import Axios from 'axios'
import { setUserData } from '../redux/actions'
import { connect } from 'react-redux'
const editProfileReducer = function(state = {}, action) {
    switch(action.type) {
        case 'SET_ALL': {
            return {
                ...action.payload
            }
        }
        case 'SET_ONE': {
            return {
                ...state,
                [action.field]: action.payload
            }
        }
        default: return state
    }
}
function EditProfile(props) {
    const [newProfile, dispatch]= useReducer(editProfileReducer, {});
    const [showPass, setShowPass]= useState(false);
    const {profile} = props;
    const [showError, setshowError] = useState(false);
    const [showSuccess, setshowSuccess] = useState(false);
    const [showLoader, setshowLoader] = useState(false);
    useEffect(() => {
        dispatch({
            type: 'SET_ALL',
            payload: {
                username: profile.username,
                phone: profile.phone,
                email: profile.email
            }
        })
    }, [profile]);

    function toggleChangePassControls() {
        setShowPass(!showPass);
    }

    async function handleFormSubmit(e) {
        e.preventDefault();
        if(newProfile.phone && checkPhoneNumber(newProfile.phone, 'phone-number')) {
            setshowLoader(true);
            setshowSuccess(false);
            setshowError(false);
            if(showPass) {
                if(checkPasswords(newProfile.password, newProfile.confirmPassword, 'confirm-password') )  {
                    delete newProfile['confirmPassword'];
                } else {
                    setshowLoader(false);
                    return;
                }
            }
                
            try {
                    console.log(newProfile);
                    await Axios.patch('/user', newProfile);
                    props.setUserDataMain({
                        ...props.userDetails,
                    ...newProfile
                    });
                    setshowLoader(false);
                    setshowSuccess(true);
                    setshowError(false);
            } catch (error) {
                setshowLoader(false);
                setshowSuccess(false);
                setshowError(true);
            }
        } else {
            return;
        }
 

    }

    return (
        <>
        <div className="col-md-8" style={{position: 'relative'}}>
            <div className="card">
            <div className="card-header d-flex align-items-center" style={{backgroundColor: '#ffffff', borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}}>
                <h3>Edit Profile</h3>
            </div>
            <div className="card-body">
                <form onSubmit={e => handleFormSubmit(e)}>
                    {
                        showLoader ? <SpinnerComponent /> : ''
                    }
                    {
                        showError ? <ErrorComponent /> : ''
                    }
                    {
                        showSuccess ?
                        <div className="alert alert-success" role="alert">
                        Profile Updated Successfully
                        </div> : ''
                    }
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Username</label>
                        <input type="text" className="form-control" id="username" aria-describedby="emailHelp"
                        onChange={(e) => dispatch({
                            type: 'SET_ONE',
                            field: 'username',
                            payload: e.target.value
                        })}
                        required
                        minLength="5"
                        defaultValue={profile && profile.username} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Phone Number</label>
                        <input 
                            type="text" 
                            className="form-control" id="phone-number"
                            onChange={(e) => {
                                    dispatch({
                                        type: 'SET_ONE',
                                        field: 'phone',
                                        payload: e.target.value
                                    });
                                    e.target.setCustomValidity('')
                                }}
                            required
                            defaultValue={profile && profile.phone}
                            minLength = "10" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Email Address</label>
                        <input 
                            type="text" 
                            className="form-control" id="email-address"
                            disabled={true}
                            defaultValue={profile && profile.email} />
                    </div>
                    <div className="form-group form-check">
                        <input type="checkbox" className="form-check-input" id="changePassword" onChange={(e) => toggleChangePassControls()}/>
                        <label className="form-check-label" htmlFor="changePassword" >Change Password</label>
                    </div>
                    {
                        showPass? 
                        <>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Enter Password</label>
                            <input 
                                type="password" 
                                className="form-control" id="password"
                                onChange={(e) => dispatch({
                                    type: 'SET_ONE',
                                    field: 'password',
                                    payload: e.target.value
                                })}
                            required
                            minLength="8" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Confirm Password</label>
                            <input 
                                type="password" 
                                className="form-control" id="confirm-password"
                                onChange={(e) => {
                                    dispatch({
                                        type: 'SET_ONE',
                                        field: 'confirmPassword',
                                        payload: e.target.value
                                    });
                                    e.target.setCustomValidity('');
                                }}
                                required
                                minLength="8"  />
                        </div>
                        </>: ''
                    }
                    <button type="submit" className="btn btn-primary">Submit</button> 
                </form>  
            </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserDataMain: (data) => (dispatch(setUserData(data))),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
