import React, { useEffect, useState } from 'react'
import './css/multi-carousel.scss';
import {getCoursesCards} from '../utils/util'
import { useRouteMatch, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCourseData } from '../redux/actions';
import Axios from 'axios';
import SpinnerComponent from './common/SpinnerComponent';
import ErrorComponent from './common/ErrorComponent';

function CoursesHomepage(props) {
    const { path} =useRouteMatch();
    const history = useHistory();
    const [fetchingCourses, setfetchingCourses] = useState(true);
    const [hasError, setShowError] = useState(false);
    const {coursesData, setAllCourses} = props;

    useEffect(() => {
        async function checkCourses() 
        {
            if(!coursesData) {
                try {
                    const res = await Axios.get('/courses');
                    setAllCourses(res.data.courses.filter(course => course.active === true));
                    setfetchingCourses(false);
                } catch(error) {
                    setShowError(true);
                    setfetchingCourses(false);
                }
            } else {
                setfetchingCourses(false)
            }
        }
        checkCourses();
    }, [coursesData, setAllCourses]);

    function handleClick(id) {
        history.push(path + '/'+ id);
    }

    return (
        <div style={{position: 'relative'}}>
            {
                fetchingCourses? <SpinnerComponent/> : 
                hasError? <div className="pl-4"><ErrorComponent message="Unable to fetch courses. Please try again!"/></div>:
                <div className="top-content py-5">
                    <div className="container">
                        <h3
                            className="mb-0 course-header text-primary" 
                            style={{letterSpacing: 0}}>
                            Courses We Offer
                        </h3>
                        <hr className="course-section"></hr>
                        <div className="row w-100 mx-auto" style={{padding: '10px'}}role="listbox">
                            { coursesData? getCoursesCards(coursesData, (id) => handleClick(id)) : <ErrorComponent message='No Courses Found! Please come back after sometime'></ErrorComponent>}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        coursesData: state.courseData
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setAllCourses: (courseData) => dispatch(setCourseData(courseData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesHomepage);
