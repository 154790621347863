import React, { useState, createRef, useEffect, useReducer } from 'react'
import { useParams, useRouteMatch, Link, Switch, Route, Redirect } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import EditSubject from './SubjectEdit';
import CourseAddAndEdit from './CourseAddAndEdit';
import SpinnerComponent from '../common/SpinnerComponent';
import axios from 'axios';
import ErrorComponent from '../common/ErrorComponent';
import Axios from 'axios';


function getNewISTDate() {
    let d = new Date()
    return new Date(d.getTime() + ( 5.5 * 60 * 60 * 1000 ))
}
const liveClassReducer = function(state, action) {
    switch(action.type) {
        case 'SET_ALL': {
            return action.value
        }
        case 'RESET_ALL': {
            return {
                startTime: '06:00',
                startPeriod: 'PM',
                endTime: '07:00',
                endPeriod: 'PM',
                date: getNewISTDate().toISOString().substring(0,10)
            }
        }
        default: return {
            ...state,
            [action.field]: action.value
        }
    }
}
export default function CourseEditMainPage(props) {
    const { courseId } = useParams();
    const [fetchingDetails, setFetching] = useState(false);
    const [hasError, showError] = useState(false);
    const [courseDetails, setCourseDetails] = useState(null);
    const [showAddSubject, setAddSubjectState] = useState(false);
    const [showLiveClass, setShowLiveClass] = useState(false);
    const [showLiveAddSuccess, setShowLiveAddSuccess] = useState(false);
    const [showLiveDltSuccess, setShowLiveDltSuccess] = useState(false);
    const {url, path} = useRouteMatch();
    const inputBoxRef = createRef();
    const {courses, setCourses} = props;
    const [liveClassDetails, dispatch]= useReducer(liveClassReducer, {});
    const [sendingLiveClassRequest, setSendingLiveClassRequest] = useState(false);

    useEffect(() => {
        setFetching(true);
        (async function () {
        try{
            const res = await axios.get('/admin/course/'+ courseId);
            const course = courses.find(course => course.courseId === courseId);
            setCourseDetails({
                ...course,
                subjects: res.data.subjects? res.data.subjects: [],
                assignments: res.data.assignments? res.data.assignments: [],
                liveDetails: res.data.liveDetails
            });
            checkLiveDetails(res.data);
            setFetching(false);
        } catch( error) {
            console.log(error)
            setFetching(false);
            showError(true);
        }})();
    }, [courseId]);

    function checkLiveDetails(course) {
        if(course && course.liveDetails) {
            console.log(course.liveDetails)
            dispatch({
                type: 'SET_ALL',
                value: {
                    description: course.liveDetails.description,
                    date: course.liveDetails.date,
                    startTime: course.liveDetails.startTime.substring(0,5),
                    endTime: course.liveDetails.endTime.substring(0,5),
                    startPeriod: course.liveDetails.startTime.substring(5,7),
                    endPeriod: course.liveDetails.endTime.substring(5,7),
                    videoUrl: course.liveDetails.videoUrl,
                    chatUrl: course.liveDetails.chatUrl,
                }
            })
        } else {
            dispatch({
                type: 'RESET_ALL'
            })
        }
    }
    
    function updateCourseDetails(courseDetails) {
        setCourseDetails(courseDetails);
        updateParentCourseDetails(courseDetails)
    }

    function updateParentCourseDetails(newCourseDetails) {
        setCourses(courses.map(course => {
            return course.courseId === courseDetails.courseId? newCourseDetails: course
        }));
    }

    async function addSubject() {
        setFetching(true);
        showError(false);
        try {
            const newSubject = {
                title: inputBoxRef.current.value,
                description: '',
                active: false,
                image: ''
            };
            const res = await axios.post('/course/subject', {
                courseId: courseDetails.courseId,
                subject: newSubject
            });
            const newSubjects = Array.prototype.slice.apply(courseDetails.subjects || []);
            newSubjects.push({
                ...res.data.subject,
                videos: 0,
                assignments: 0
            });
            const newCourseDetails = {
                ...courseDetails,
                subjects: newSubjects
            };
            setCourseDetails(newCourseDetails)
            setAddSubjectState(false);
            setFetching(false);
            updateParentCourseDetails(newCourseDetails);
        } catch(error) {
            setFetching(false);
            showError(true);
        }
    }

    async function toggleActivation(id, active) {
        setFetching(true);
        showError(false);
        try {
            await axios.patch('/course/subject', {
                courseId: courseDetails.courseId,
                subject: {
                    subjectId: id,
                    active: !active
                }
            });
            const newCourseDetails = {
                ...courseDetails,
                subjects: courseDetails.subjects.map(subject => {
                    if(subject.subjectId !== id) {
                        return subject;
                    } else {
                        return {
                            ...subject,
                            active: !active
                        }
                    }
                })
            }
            setCourseDetails(newCourseDetails);
            setAddSubjectState(false);
            setFetching(false);
            updateParentCourseDetails(newCourseDetails);
        } catch(error) {
            setFetching(false);
            showError(true);
        }
    }

    const columns = [
        {
            name: 'Subjects',
            selector: 'title',
            sortable: false
        },
        {
            name: 'Total Videos',
            cell: (row) => (row.videos && Array.isArray(row.videos))? row.videos.length: (row.videos || 0 )
        },
        {
            name: 'Total Assignments',
            cell: (row) => (row.assignments && Array.isArray(row.assignments))? row.assignments.length: (row.assignments || 0 )
        },
        {
            name: 'Status',
            cell: (row) => <span className={"badge "+(row.active? "badge-success": "badge-danger")}>{row.active? 'Activated': 'Deactivated'}</span>
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                <Link to = {`${url}/${row.subjectId}`}>
                    <button title="Edit" className="btn btn-primary btn-circle edit-btn">
                        <svg className="bi bi-pencil" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M11.293 1.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z" clipRule="evenodd"/>
                            <path fillRule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 00.5.5H4v.5a.5.5 0 00.5.5H5v.5a.5.5 0 00.5.5H6v-1.5a.5.5 0 00-.5-.5H5v-.5a.5.5 0 00-.5-.5H3z" clipRule="evenodd"/>
                        </svg>
                    </button>
                </Link>
                <button title={row.active? 'Deactivate': 'Activate'} className={"btn btn-circle " + (row.active? 'btn-danger': 'btn-success')} onClick={_ => toggleActivation(row.subjectId, row.active)}>
                    <svg className="bi bi-power" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M5.578 4.437a5 5 0 104.922.044l.5-.866a6 6 0 11-5.908-.053l.486.875z" clipRule="evenodd"/>
                        <path fillRule="evenodd" d="M7.5 8V1h1v7h-1z" clipRule="evenodd"/>
                    </svg>
                </button>
                </>
            ),
        }
    ];

    function timingOptions() {
        const options = [];
        for(let i = 1; i <= 12; i++) {
            i = i + "";
            options.push(<option value={i.padStart(2, '0')+":00"} key={i+':00'}>{i+":00"}</option>);   
            options.push(<option value={i.padStart(2, '0')+":30"} key={i+':30'}>{i+":30"}</option>);   
        }
        return options;
    }

    function resetMsgs() {
        setShowLiveAddSuccess(false);
        setShowLiveDltSuccess(false);
    }

    async function  updateLiveClass(e) {
        e.preventDefault();
        const obj = {
            description: liveClassDetails.description,
            date: liveClassDetails.date,
            chatUrl: liveClassDetails.chatUrl,
            videoUrl: liveClassDetails.videoUrl,
            startTime: liveClassDetails.startTime + liveClassDetails.startPeriod,
            endTime: liveClassDetails.endTime + liveClassDetails.endPeriod
        };
        // API request

        try {
            showError(false);
            let res;
            const url = '/course/'+ courseDetails.courseId+ '/live';
            setSendingLiveClassRequest(true);
            if(courseDetails.liveDetails) {
                res  = await Axios.patch(url , obj);
                setCourseDetails({
                    ...courseDetails,
                    liveDetails: obj
                });
            } else {
                res = await Axios.post(url, obj);
                setCourseDetails({
                    ...courseDetails,
                    liveDetails: obj
                });
            }
            setShowLiveClass(false);
            setShowLiveAddSuccess(true);
        } catch(error) {
            showError(true);
        } finally {
            setSendingLiveClassRequest(false);
        }
    }

    async function dltLiveClass() {
        showError(false);
        try {
            const url = '/course/'+ courseDetails.courseId+ '/live';
            setSendingLiveClassRequest(true);
            await Axios.delete(url);
            setCourseDetails({
                ...courseDetails,
                liveDetails: null
            });
            setShowLiveClass(false);
            setShowLiveDltSuccess(true);
        } catch(error) {
            showError(true);
        } finally {
            setSendingLiveClassRequest(false);
        }
    }

    return (
        <>
            {
                courseDetails ?
                    <Switch>
                        <Route path = {path + '/edit-course-details'}>
                            <CourseAddAndEdit setCourseDetails={updateCourseDetails} courseDetails = {courseDetails} /> 
                        </Route>
                        <Route path = {path + '/:subjectId'} render={(props) => <EditSubject courseDetails = {courseDetails} {...props} setCourseDetails={updateCourseDetails}/>}>
                        </Route>
                        <Route path = {path} exact>
                        <div className="col-12 col-md-10 p-0" style={{margin: 'auto', position: 'relative'}}>
                            {fetchingDetails ? <SpinnerComponent/> : ''}
                            {showLiveAddSuccess? 
                                <div class="alert alert-success" role="alert">
                                Live Class Updated Successfully
                                </div>: ''
                            }
                            {showLiveDltSuccess? 
                                <div class="alert alert-success" role="alert">
                                Live Class Deleted Successfully
                                </div>: ''
                            }
                            <DataTable
                                title={courseDetails.title}
                                columns={columns}
                                data={courseDetails.subjects}
                                keyField="title"
                                pagination = {true}
                                highlightOnHover = {true}
                                paginationPerPage = {8}
                                subHeader = {true}
                                subHeaderAlign = 'left'
                                subHeaderComponent={
                                    <div  style = {{margin: '0 -10px', width: '100%'}}>
                                        <Link to={
                                            {
                                            pathname: url + '/edit-course-details',
                                            state: {
                                                courseDetails: courseDetails
                                            }
                                        }}>
                                            <button className="btn btn-success">
                                            <svg className="bi bi-pencil-square" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.502 1.94a.5.5 0 010 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z"/>
                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 002.5 15h11a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H9a.5.5 0 000-1H2.5A1.5 1.5 0 001 2.5v11z" clipRule="evenodd"/>
                                            </svg>&nbsp;Edit Course Details</button>
                                        </Link>
                                        {
                                            !showAddSubject ? 
                                            <>
                                            <button className="btn btn-success ml-2" onClick = {e => {setAddSubjectState(true);resetMsgs();setShowLiveClass(false)}}>
                                                <svg className="bi bi-plus-square-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm6.5 4a.5.5 0 00-1 0v3.5H4a.5.5 0 000 1h3.5V12a.5.5 0 001 0V8.5H12a.5.5 0 000-1H8.5V4z" clipRule="evenodd"/>
                                                </svg> &nbsp;
                                                Add New Subject</button>
                                            </>
                                            :
                                            <div className="form-group my-3">
                                                {(hasError && showAddSubject)? <ErrorComponent />: ''}
                                                <label htmlFor="exampleInputEmail1">Subject Name</label>
                                                <input type="text" ref={inputBoxRef} className="form-control" id="subject-name" placeholder="Enter Subject Name" />
                                                <button type="button" className="btn btn-primary mt-2" onClick={()=> addSubject()}>Add Subject</button>
                                            </div>
                                        }
                                        {
                                            !showLiveClass ? 
                                            <>
                                            <button className="btn btn-success ml-2" onClick = {e => {checkLiveDetails(courseDetails);resetMsgs();setShowLiveClass(true);setAddSubjectState(false)}}>
                                                <svg className="bi bi-pencil-square" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.502 1.94a.5.5 0 010 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z"/>
                                                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 002.5 15h11a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H9a.5.5 0 000-1H2.5A1.5 1.5 0 001 2.5v11z" clipRule="evenodd"/>
                                                </svg>&nbsp;
                                                Update Live Class</button>
                                            </>
                                            :
                                            <form name="live-class-form" style={{position: 'relative'}}className="form-group my-3" onSubmit={e => updateLiveClass(e)}>
                                            {sendingLiveClassRequest && <SpinnerComponent/>}
                                            {(hasError && showLiveClass)? <ErrorComponent />: ''}
                                                <label htmlFor="lecture-desc">Lecture Short Description</label>
                                                <input type="text"  required className="form-control" id="lecture-desc" placeholder="Lecture Description" 
                                                    onChange={e => dispatch({
                                                        field: 'description',
                                                        value: e.target.value
                                                    })} 
                                                    defaultValue={liveClassDetails.description}
                                                /><br/>

                                                <label htmlFor="lecture-date">Date</label>
                                                <input type="date"  required className="form-control" id="lecture-date" defaultValue = {liveClassDetails.date} 
                                                    onChange={e => dispatch({
                                                        field: 'date',
                                                        value: e.target.value
                                                    })}
                                                /><br/>
                                                
                                                <label htmlFor="lecture-start-time">Start Time&nbsp;&nbsp;</label>
                                                <select  style={{display: 'inline-block'}} id="lecture-start-time" defaultValue={liveClassDetails.startTime}
                                                    onChange={e => dispatch({
                                                        field: 'startTime',
                                                        value: e.target.value
                                                    })}
                                                >
                                                    {timingOptions()}
                                                </select>
                                                
                                                <select  style={{display: 'inline-block'}} id="lecture-start-period" defaultValue={liveClassDetails.startPeriod}
                                                    onChange={e => dispatch({
                                                        field: 'startPeriod',
                                                        value: e.target.value
                                                    })}
                                                >
                                                    <option value="AM">AM</option><option>PM</option>
                                                </select>
                                                
                                                <label htmlFor="lecture-end-time">&nbsp;&nbsp;End Time&nbsp;&nbsp;</label>
                                                <select  style={{display: 'inline-block'}} id="lecture-end-time" defaultValue={liveClassDetails.endTime}
                                                    onChange={e => dispatch({
                                                        field: 'endTime',
                                                        value: e.target.value
                                                    })}
                                                >
                                                    {timingOptions()}
                                                </select>
                                                
                                                <select  style={{display: 'inline-block'}} id="lecture-end-period" defaultValue={liveClassDetails.endPeriod}
                                                    onChange={e => dispatch({
                                                        field: 'endPeriod',
                                                        value: e.target.value
                                                    })}
                                                >
                                                    <option value="AM">AM</option><option >PM</option>
                                                </select><br/><br/>
                                                
                                                <label htmlFor="lecture-url">Lecture Video URL (Iframe Link)</label>
                                                <input type="text"  required className="form-control" id="lecture-url" placeholder="Lecture Video URL" 
                                                    onChange={e => dispatch({
                                                        field: 'videoUrl',
                                                        value: e.target.value
                                                    })}
                                                    defaultValue={liveClassDetails.videoUrl}
                                                /><br/>

                                                <label htmlFor="lecture-chat-url">Lecture Chat URL (Iframe Link)</label>
                                                <input type="text"  required className="form-control" id="lecture-chat-url" placeholder="Lecture Chat URL" 
                                                    onChange={e => dispatch({
                                                        field: 'chatUrl',
                                                        value: e.target.value
                                                    })}
                                                    defaultValue={liveClassDetails.chatUrl}
                                                /><br/>

                                                <button className="btn btn-primary mt-2">Update Class</button>
                                                {courseDetails.liveDetails? <button type="button" className="btn btn-danger mt-2"  onClick={e => dltLiveClass(e)}>Delete Class</button>: ''}
                                                <button type="button" className="btn btn-info mt-2" onClick={e => setShowLiveClass(false)}>Cancel</button>
                                            </form>
                                        }
                                    </div>
                                
                                }
                                paginationRowsPerPageOptions = {[8, 10, 15]}
                            />
                            </div>
                        </Route>
                        <Route path="*">
                            <Redirect to = "/admin" />
                        </Route>
                    </Switch>
                : hasError? <ErrorComponent/>: <SpinnerComponent/>
            }
        </>
    )
}
