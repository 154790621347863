import React from 'react';
import './css/home-component.scss';
import './css/landing-page.min.css';
import { Link, Route } from 'react-router-dom';
import TestimonialsComponent from './TestimonialsComponent';
import headerBg from '../assets/images/header-bg.png';
import { connect } from 'react-redux';
import learningBg from '../assets/images/learning-bg.png';
import './css/our-team.scss';
import './css/testimonials.scss';
import ImageGalley from './ImageGalley';
import WhyGamma from './WhyGamma';
import CoursesCarousel from './CoursesCarousel';
import OurTeam from './OurTeam';
import StudentsPerformance from './StudentsPerformance';

export class HomeComponent extends React.Component {
    render() {
        return (
            <div className="home-comp">
                <div className="homepage-bg bg-gradient-primary" style={{position: 'relative'}}>
                    <div className="container d-flex flex-column justify-content-center align-items-start">
                        <div className="p-3 row" style={{width: '100%'}}>
                        <div className=" col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
                            <h1 className="header text-center" style={{fontSize: '2.5rem', color: 'white', fontWeight: '400'}}>Welcome To Gamma Maths Academy</h1><br/>
                            <h2 className="text-center" style={{color: 'white', fontWeight: 400}}> One place for all your math requirements</h2><br/>
                            <div className="row">
                            {
                                this.props.isLoggedIn ? '': 
                                <Link to = "/user/register">
                                    <button type="button" className="btn btn-outline-primary get-started-btn">Let's Go</button>
                                </Link>
                            }
                                <a class="ml-3" href="https://testseries.gammamathsacademy.com/" target="_blank">
                                    <button type="button" class="btn btn-outline-primary get-started-btn">Online Testseries</button>
                                </a>
                            </div>
                        </div>
                        {/* <div className="container-custom-home d-flex col-md-8 flex-wrap mt-4 p-4" style={{backgroundColor: 'rgba(255, 255, 255, 0.95)'}}>
                            <div className="col-sm-4 text-center">
                                <p>Watch Daily Videos</p>
                            </div>
                            <div className="col-sm-4 text-center">
                                <p>Learn From Top Mentors</p>
                            </div>
                            <div className="col-sm-4 text-center">
                                <p style={{marginBottom: '0px'}}>Get Daily Assignments</p>
                            </div>
                        </div> */}
                        <div className="col-lg-6 img-wrapper">
                            <img src={headerBg} alt="" style={{height: '100%', width: '100%',animation: 'scale-zoom 2s ease-in-out infinite alternate-reverse both'}}></img>
                           
                        </div>
                        </div>
                    </div>
                    <div className="svg-border-rounded text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" style={{position: 'absolute', bottom: '0', left: 0, height: '3rem', width: '100%'}}viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="#fcfcfc"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                    </div>
                </div>
                <section id="pricing" className="pricing p-1">
                    <div className="container d-flex flex-column justify-content-center align-items-center">
                        <div className="section-title d-flex flex-column align-items-center pb-0">
                            <p className="text-primary" style={{fontWeight: '600', fontSize: '36px'}}>How to Get Started</p>
                        </div>
                        <img src={learningBg} className="w-100 mb-5  mt-0 b-12" style={{maxWidth: '250px'}} alt=""/>
                        <div className="row get-started-row justify-content-between align-items-strech w-100">
                            <div className="col-lg-4 aos-init aos-animate mx-15" data-aos="fade-right">
                                <div className="card sp-card p-3 text-center">
                                    <h3>Sign Up</h3>
                                    <p>Create Your account to get started</p>
                                    <span className="get-started-btn mt-auto mb-3">
                                        <Link to="/user/register">
                                            <span>Create Now</span>
                                        </Link>
                                    </span>
                                </div>
                            </div>

                            <div className="col-lg-4 aos-init aos-animate mx-15" data-aos="fade-right">
                                <div className="card sp-card p-3 text-center">
                                    <h3>Buy a Course</h3>
                                    <p>Buy a course you want to enroll for</p>
                                    <span className="get-started-btn mt-auto mb-3">
                                        <Link to="/courses">
                                            <span>Browse Courses</span>
                                        </Link>
                                    </span>
                                </div>
                            </div>

                            <div className="col-lg-4 aos-init aos-animate mx-15" data-aos="fade-right">
                                <div className="card sp-card p-3 text-center">
                                    <h3>Enjoy Online Classes</h3>
                                    <p>Watch High Quality videos anywhere, anytime.</p>  
                                    <span className="get-started-btn mt-auto mb-3">
                                        <Link to="/user/login">
                                            <span className="get-started-btn mt-auto mb-3">Enroll Now</span>
                                        </Link>    
                                    </span>                              
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CoursesCarousel name="testimonials"/>
                <WhyGamma/>
                <Route component={StudentsPerformance}></Route>
                <OurTeam/>
                <TestimonialsComponent/>
                <Route component={ImageGalley}></Route>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.logInState
    }
}

export default connect(mapStateToProps)(HomeComponent);
