import React from 'react'
import gopiSir from '../assets/images/gopiSir.jpg';
import navinSir from '../assets/images/navin.jpeg';

export default function OurTeam() {
    return (
        <section id="team" className="team">
            <div className="container">

                <div className="section-title aos-init aos-animate" data-aos="fade-up">
                    <p className="text-primary">Our Great Team</p>
                </div>

                <div className="row aos-init aos-animate" data-aos="fade-left">

                    <div className="col-lg-3 col-md-6">
                        <div className="member aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                            <div className="pic"><img src={gopiSir} className="img-fluid" style={{height: '300px', maxHeight: '300px', width: '100%'}} alt="" /></div>
                                <div className="member-info">
                                    <h4>Dr. Gopi Chand Yadav</h4>
                                    <span>Director</span>
                                    <div className="social">
                                        {/* <a href=""><i className="icofont-twitter"></i></a>
                                        <a href=""><i className="icofont-facebook"></i></a>
                                        <a href=""><i className="icofont-instagram"></i></a>
                                        <a href=""><i className="icofont-linkedin"></i></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 mt-5 mt-md-0">
                            <div className="member aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
                                <div className="pic"><img src={navinSir} className="img-fluid" style={{height: '300px', maxHeight: '300px', width: '100%'}} alt="" /></div>
                                <div className="member-info">
                                    <h4>Mr. Navin Yadav</h4>
                                    <span>Managing Director</span>
                                    <div className="social">
                                        {/* <a href=""><i className="icofont-twitter"></i></a>
                                        <a href=""><i className="icofont-facebook"></i></a>
                                        <a href=""><i className="icofont-instagram"></i></a>
                                        <a href=""><i className="icofont-linkedin"></i></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 mt-6 mt-lg-0 pb-1 youtube-video" style={{marginLeft: 'auto'}}>
                            <iframe src='https://www.youtube.com/embed/fJ58TU9mpzg'
                                        frameBorder='0'
                                        allow='autoplay; encrypted-media'
                                        allowFullScreen
                                        title='video'
                                        width='100%'
                                        height='100%'
                            />
                        </div>
                    </div>
                </div>
    </section>
    )
}
