import React, { useReducer, useState, useEffect } from 'react'
import SpinnerComponent from '../common/SpinnerComponent';
import axios from 'axios';
import ErrorComponent from '../common/ErrorComponent';
import { MM, formatDate } from '../../utils/util';
import { useHistory } from 'react-router-dom';

export const courseDetailsReducer = function(state, action) {
    switch (action.type) {
        default: return {
            ...state,
            [action.type]: action.value
        }
    }
}

const mainCardStyle = {
    boxShadow: '0 6px 10px -4px rgba(0, 0, 0, 0.15)',
    borderRadius: '12px',
    margin: 'auto',
    padding: '30px',
    backgroundColor: '#ffffff',
    maxWidth: '900px',
    position: 'relative'
}

export default function CourseAddAndEdit(props) {
    const [formData, dispatch] = useReducer(courseDetailsReducer, {});
    const [formHidden, hideForm] = useState(false);
    const [showError, setShowError] = useState(false);
    const {courseDetails} = props; 
    const history = useHistory();

    async function parseForm(event) {
        event.preventDefault();


        hideForm(true);
        setShowError(false);
        try {
            if(props.courseDetails) {
                // const formDetails = getFormData(formData);
                formData.courseId = props.courseDetails.courseId;
                await axios.patch('/course', formData);
                props.setCourseDetails({
                    ...props.courseDetails,
                    ...formData
                });
                history.replace('/admin/courses/edit-course/'+props.courseDetails.courseId);
                hideForm(false);
            } else {
                const formDetails = getFormData(formData);
                const res =  await axios.post('/course', formDetails);
                hideForm(false);
                const newCourse = res.data.result;
                newCourse['startDate'] = formatDate(newCourse.startDate);
                newCourse['endDate'] = formatDate(newCourse.endDate)
                props.courses.push(newCourse);
                props.setCourses([...props.courses]);
                history.replace('/admin/courses');
            }
        } catch(error) {
            setShowError(true);
            hideForm(false);
        }
    }

    function getFormData(formData) {
        const formDetails = new FormData();
        for (let key in formData) {
            formDetails.append(key, formData[key]);
        }
        const file = document.getElementById('input-image');
        if(file.value) {
            var fullPath = file.value, filename;
            if (fullPath) {
                var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
                filename = fullPath.substring(startIndex);
                if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                    filename = filename.substring(1);
                }
            }
            formDetails.append('image', file.files[0], filename);
        }
        return formDetails;
    }

    useEffect(() => {
        if(courseDetails) {
            // document.getElementById('input-image').removeAttribute('required');
        } else {
            document.getElementById('input-image').setAttribute('required', true);
        }
    }, [courseDetails])

    function getDate(data) {
        if(data) {
            const arr = data.split(' ');
            arr[1] = `${(MM.indexOf(arr[1]) + 1)}`.padStart(2, "0");
            return arr.reverse().join("-");
        }
    }
    return (
        <div className="d-flex flex-column align-items-center" >
            <form onSubmit={(e) => parseForm(e)}>
                <div className="main-card" style={mainCardStyle}>
                {formHidden ? <SpinnerComponent/>: ''}
                    <h2>{
                    props.courseDetails ? 
                        (<span>Edit Course Details</span>):
                        (<span>Add New Course</span>)
                    }</h2>
                    {showError ? <ErrorComponent/>: ''}
                    <div className="form-container row">
                        <div className="form-group mt-3 col-md-12 pr-5">
                            <label className="font-weight-bold">Course Name:</label>
                            <input type="text" className="form-control" id="course-name" placeholder="Name" aria-describedby="emailHelp" required
                                onChange = {(e) => dispatch({type: 'title', value: e.target.value})}
                                defaultValue = {props.courseDetails && props.courseDetails.title}
                            />
                        </div>
                        <div className="col-md-12 row">
                            <div className="form-group col-md-6">
                                <label className="font-weight-bold">Start Date:</label>
                                <input type="date" className="form-control" id="course-start-date" required
                                    onChange = {(e) => dispatch({type: 'startDate', value: e.target.value})}
                                    defaultValue = {props.courseDetails? getDate(props.courseDetails.startDate): ''}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label className="font-weight-bold">End Date:</label>
                                <input type="date" className="form-control" id="course-end-date" required
                                    onChange = {(e) => dispatch({type: 'endDate', value: e.target.value})}
                                    defaultValue = {props.courseDetails ? getDate(props.courseDetails.endDate): ''}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 row">
                            <div className="form-group  col-md-6">
                                <label className="font-weight-bold">Price:</label>
                                <input type="text" className="form-control" placeholder="Price" id="course-price" required
                                    onChange = {(e) => dispatch({type: 'price', value: e.target.value})}
                                    defaultValue = {props.courseDetails && props.courseDetails.price}
                                />
                            </div>
                            <div className="form-group  col-md-6">
                                <label className="font-weight-bold">Price After Discount:</label>
                                <input type="text" className="form-control" placeholder="Please enter discounted price if applicable" id="course-discount-price" 
                                    onChange = {(e) => dispatch({type: 'discount', value: e.target.value})}
                                    defaultValue = {props.courseDetails && props.courseDetails.discount}
                                />
                            </div>
                        </div>
                        {
                            !props.courseDetails && <div className="col-md-12 pr-5">
                                <label className="font-weight-bold">Course Poster Image:</label>
                                <input type="file" className="form-control-file" name="image" id="input-image"/>
                            </div>
                        }
                        <div className="form-group col-md-12 pr-5 mt-3">
                            <label className="font-weight-bold">Course Description:</label>
                            <textarea rows="3" className="form-control" placeholder="Short description" id="course-description" required
                                onChange = {(e) => dispatch({type: 'description', value: e.target.value})}
                                defaultValue = {props.courseDetails && props.courseDetails.description}
                            />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">
                        <span>{
                            props.courseDetails? 'Save Changes' : 'ADD COURSE'
                        }</span>
                        </button>
                </div>
            </form>
        </div>
    )
}
