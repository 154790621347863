import React from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

export default function ShowImageComponent(props) {
    function getImages() {
        const images = [];
        if(props.match.params.type === 'performance') {
            for (let i = 1; i <= 9; i++) {
                const src = process.env.PUBLIC_URL + '/students/' + i + '.jpeg';
                images.push({
                    original: src,
                    thumbnail: src
                })
            }

        } else {
            for (let i = 1; i <= 20; i++) {
                const src = process.env.PUBLIC_URL + '/institute/' + i + '.jpg';
                images.push({
                    original: src,
                    thumbnail: src
                })
            }
        }
        return images;
    }
    return (
            <ImageGallery items={getImages()} startIndex={+props.match.params.id.substring(0, props.match.params.id.length - 4) - 1}/>
    )
}
