import React, { useState, useReducer } from 'react'
import { checkPasswords } from '../utils/util';
import Axios from 'axios';
import ErrorComponent from './common/ErrorComponent';
import { Redirect } from 'react-router-dom';
import SpinnerComponent from './common/SpinnerComponent';

const reducer = (state, action) => {
    switch(action.type) {
        default: return {
            ...state,
            [action.type]: action.value
        }
    }
}
export default function ForgotPassword() {
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [formState, dispatch] = useReducer(reducer, {
            otp: '',
            confirmPassword: '',
            password: '',
    });
    const [showLoader, setshowLoader] = useState(false);
    const [showError, setshowError] = useState('');
    const [email, setEmail]=useState('');
    const [redirectLogin, setRedirectLogin]=useState(false);

    async function sendPasswordRecoveryMail(event) {
        event.preventDefault();
        setshowLoader(true);
        setshowError(false);
        try{
            const obj = {
                email: email
            }
            await Axios.post('/user/forget-password', obj);
            setShowResetPassword(true);
            setshowLoader(false);
        } catch(error) {
            if(error.response && (error.response.status === 412 || error.response.status === 503)) {
                setshowError('Email Id doesn\'t exist');
            } else  {
                setshowError('Some Error Occured. Please try again');
            }
            setshowLoader(false);
        }
    }

    async function verifyPassword(event) {
        event.preventDefault();
        if(checkPasswords(formState.password, formState.confirmPassword, 'confirmPassword')) {
            setshowLoader(true);
            setshowError(false);
             try{
               const obj = {
                    password: formState.password,
                    otp: formState.otp,
                    email: email
                }
               await Axios.patch('/user/reset-password', obj);
                setRedirectLogin(true);
             } catch(error) {
              if(error.response && error.response.status === 503) {
                setshowError('Invalid OTP');
              } else  {
                setshowError('Some Error Occured. Please try again');
              }
              setshowLoader(false);
             }
          }
    }
    return (
        <>
        {
            redirectLogin?   <Redirect to={{
                    pathname: "/user/login",
                    state: {
                        message: 'Password Reset Successful. Please login to continue'
                    }
                }}></Redirect>
            :
            !showResetPassword? <form name="email-form" onSubmit={(e) => sendPasswordRecoveryMail(e)}>
                    <h4 className="mt-1">Password Recovery</h4>
                    {
                        showError && <ErrorComponent message={showError}/>
                    }
                    <div className="input-icons"> 
                        <i className="fa fa-envelope"></i> 
                        <input type="email" id="inputEmail" name="email" className="form-control admin-username" 
                            placeholder="Please enter your Email Address" required
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                        />
                    </div>
                    <button className="btn btn-primary btn-block mt-3">Verify Email</button>
            </form>
            :
            <form name="otp-form" style={{position: 'relative'}} onSubmit={(e) => verifyPassword(e)}>
                    <h4 className="mt-1">Password Recovery</h4>
                    {
                        showError && <ErrorComponent  message={showError}/>
                    }
                    {
                        showLoader && <SpinnerComponent/>
                    }
                    <p className="mb-2">We have sent an OTP to given email. Please enter OTP and the new password</p>
                        <div className="input-icons"> 
                            <i className="fa fa-key"></i> 
                                 <input type="text" id="inputOtp" name="otp-input" className="form-control" 
                                placeholder="Please enter OTP received on the given email address" 
                                onChange={e => dispatch({
                                    type: 'otp',
                                    value: e.target.value
                                })}
                                value={formState.otp}
                                required
                            />
                        </div>
                        <div className="input-icons"> 
                            <i className="fa fa-unlock-alt"></i> 
                            <input type="password" id="password" className="form-control" 
                                placeholder="Enter Password"
                                name="password"
                                onChange={e => dispatch({
                                    type: 'password',
                                    value: e.target.value
                                })}
                                value={formState.password}
                                required
                                minLength="8"
                            />
                        </div>
                        <div className="input-icons"> 
                            <i className="fa fa-unlock-alt"></i> 
                            <input type="password" id="confirmPassword" className="form-control" 
                                placeholder="Confirm Password"name="cPassword" 
                                onChange={e => {dispatch({
                                    type: 'confirmPassword',
                                    value: e.target.value
                                });e.target.setCustomValidity('')}}
                                value={formState.confirmPassword}
                                required
                                minLength="8"
                            />
                        </div>
                    <button className="btn btn-primary btn-block mt-3">Reset Password</button>
            </form>
        }
        </>
    )
}
