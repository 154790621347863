import React from 'react';
import './css/footer.scss';
import fb from './../assets/images/facebook.svg'
import whatsapp from './../assets/images/whatsapp.svg'
import instagram from './../assets/images/instagram.svg'
import youtube from './../assets/images/youtube.svg'

export default function FooterComponent() {
    return (
    <footer id="footer">
        <div className="footer-top ">
        <div className="container">
            <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-8">
                <div className="copyright" style={{color: 'white'}}>
                © 2020 Gamma Maths Academy | GammaMathsAcademy.com . All Rights Reserved
                </div>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-4">
                <div className="social-links" style={{float: 'right', color: 'white'}}>Socially yours
                <a href="https://www.youtube.com/channel/UC_gHaMeyMFAcKPVrg5ygTbA" className="youtube">
                    <img src = {youtube} alt="youtube-icon"></img></a>
                <a href="https://www.facebook.com/gammamaths/?rf=656436541199253" target="_blank" rel="noopener noreferrer" className="facebook">
                    <img src = {fb} alt="fb-icon"></img>
                </a>
                <a href="https://www.instagram.com/gammamaths/"  target="_blank" rel="noopener noreferrer" className="telegram">
                    <img src = {instagram} alt="twt-icon"></img>
                </a>
                <a href="https://api.whatsapp.com/send?phone=919649120570&fbclid=IwAR2l24GiiHWlNkqHNh5VU3l6NWMN7bUp91Zaoy_Txfm407_RGgdcmZtaNHw"  target="_blank" rel="noopener noreferrer" className="telegram">
                    <img src = {whatsapp} alt="whatsapp-icon"></img>
                </a>
                </div>
            </div>
            </div>
        </div>
        </div>
  </footer>
    )
}
