import React, { Component } from 'react'

export default class LiveVideoPlayer extends Component {
    player = {}
    state = {
        video: {
            videoUrl: "",
            chatUrl: ""
        }
    }

    componentDidMount() {
      if(this.props.location.state?.videoUrl || !this.props.location.state?.chatUrl) {
        this.props.history.replace(this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf('/')));
        return false;
      } 
      this.setState(prevState => {
        return {
          video: {
            videoUrl:this.props.location.state?.videoUrl,
            chatUrl: this.props.location.state?.chatUrl
          }
        }
      },() => console.log(this.state));
    }
    render() {
        return ( 
        <div className="live-video-container">
            <div className="video-player-wrapper " style={{position: 'sticky', top: 0}}>
                <iframe src={this.state.video.videoUrl} title="video-player" width="100%" id="video-player" style={{height: '100%'}}height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe> 
            </div>     
            <div className="live-video-chat">
                <iframe src={this.state.video.chatUrl} title="chat-screen" width="100%"  style={{height: '100%'}}id="chat-screen" height="100%" frameBorder="0"></iframe>   
            </div>
        </div>
        );
    }
}
