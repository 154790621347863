import React from 'react'
import ReactOwlCarousel from 'react-owl-carousel';

export default function ImageGalley(props) {
    
    const options = {
        loop: true,
        margin:10,
        nav:false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 5000,
        responsive:{
            1139:{
                items:4
            },
            720:{
                items:3
            },
            570:{
                items:2
            },
            0:{
                items:1
            }
        }
    };
    function getImages() {
        const images = [];
        for (let i = 1; i <= 20; i++) {
            const src = process.env.PUBLIC_URL + '/institute/' + i + '.jpg';
            images.push(
                <div key={i}>
                    <div className="gallery-item aos-init aos-animate" data-aos="zoom-in" style={{height: '100%'}}data-aos-delay="450">
                        <span onClick={e=> {props.history.push('/image/' + i + '.jpg')}} className="venobox vbox-item" data-gall="gallery-item">
                            <img src={src} style={{height: '150px'}} className="img-fluid w-100" alt=""/>
                        </span>
                    </div>
                </div>
            )
        }
        // setImages(img);
        return images;
    }
    return (
        <section id="gallery" className="gallery">
            <div className="container pt-5">
                <div className="section-title aos-init aos-animate" data-aos="fade-up">
                    <p className="text-primary">Check Our Gallery</p>
                </div>
                <div className="row no-gutters aos-init aos-animate" data-aos="fade-left">
                                <ReactOwlCarousel
                                    {...options}
                                >
                                    {getImages()}
                                </ReactOwlCarousel>
                <button onClick={e=> {props.history.push('/image/1.jpeg')}}  className="btn btn-outline-primary mx-auto mt-4">View All</button>
                </div>
            </div>
        </section>
    )
}
