import React, { useEffect, useState } from 'react'
import { useRouteMatch, NavLink, Switch, Route, Redirect } from 'react-router-dom';
import styles from './admin/css/sidenav.module.scss';
import DashboardComponent from './DashboardComponent';
import UserProfile from './UserProfile';
import Axios from 'axios';
import { setUserData, setUserCourses } from '../redux/actions';
import { connect } from 'react-redux';
import SpinnerComponent from './common/SpinnerComponent';

function UserHomepage({userData, setUserDetails, userCoursesSet, setUserCoursesValue}) {
    let { path, url } = useRouteMatch();
    const [isFetching, setIsFetching] = useState(true);
    function toggleSideNav(tgl) {
        if(tgl==='open') {
            document.getElementById('mySideNav').style.display = 'block';
        } else {
            document.getElementById('mySideNav').style.display = 'none';
        }
    }
    useEffect(()=> {
        if(!userCoursesSet) {
            (async function(){
                try {
                    const res = await Axios.get('/user/courses');
                    setUserDetails({
                        ...userData,
                        courses: res.data
                    });
                    setUserCoursesValue(true);
                    setIsFetching(false);
                } catch(error) {
                    
                }
            })()
        } else {
            setIsFetching(false);
        }
    }, [userCoursesSet, setUserCoursesValue, userData, setUserDetails]);
    return (
        <div  className="d-flex" style={{backgroundColor: 'fcfcfc', position: 'relative', flex: '1'}}>
            <div id="mySideNav" className={styles.sideNav + " sideNavAdmin"} >
                <span href="#" className="close-btn" onClick={e => toggleSideNav()}>
                    <svg className="bi bi-x" width="25px" height="25px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z" clipRule="evenodd"/>
                        <path fillRule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z" clipRule="evenodd"/>
                    </svg>
                </span>
                <NavLink exact to={`${url}`} activeClassName={styles.sideNavActive}  className={`${styles.sideNavLink}`} >
                    <span>
                     <i className="ni ni-shop text-primary"></i> &nbsp;
                     Home
                    </span>
                </NavLink>
                <NavLink to={`${url}/user-profile`} activeClassName={styles.sideNavActive} className={`${styles.sideNavLink}`}>
                <span>
                    <i className="ni ni-single-02 text-yellow"></i> &nbsp;
                    My Profile
                </span>
                </NavLink>
            </div>
            <span className = "sidenav-toggler" id="sidenav-toggle-btn" onClick={(e) => toggleSideNav('open')}>
                <svg className="bi bi-chevron-double-right" width="2em" height="2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L9.293 8 3.646 2.354a.5.5 0 010-.708z" clipRule="evenodd"/>
                    <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L13.293 8 7.646 2.354a.5.5 0 010-.708z" clipRule="evenodd"/>
                </svg>
            </span>
            <div className = "p-5 user-page-container" style={{flexBasis: '100%', position: 'relative', backgroundColor: '#f9f9f9'}}>
                {isFetching? <SpinnerComponent opacity="0"/>:
                    <Switch>
                        <Route path={path} exact>
                            <DashboardComponent/>
                        </Route>
                        <Route path={`${path}/user-profile`} render={(props) => <UserProfile {...props}/>} />
                        <Route path="*">
                            <Redirect to="/dashboard"/>
                        </Route>
                    </Switch>
                }
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserDetails: (userData) => dispatch(setUserData(userData)) ,
        setUserCoursesValue: (isSet) => dispatch(setUserCourses(isSet))
    }
}

const mapStateToProps = (state) => {
    return {
        userData : state.userData,
        userCoursesSet: state.userCoursesSet
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserHomepage);
