import React, { useState, createRef } from 'react'
import { Link, Switch, Route, useRouteMatch, Redirect, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import ErrorComponent from '../common/ErrorComponent';
import SpinnerComponent from '../common/SpinnerComponent';
 export default function EditSubject(props) {
    const {match: {params: {subjectId}}, courseDetails: {subjects}, courseDetails} = props;
    const foundSubject = subjects.find(subject => subject.subjectId === subjectId);
    const [subjectDetails, setSubjectDetails] = useState(foundSubject);
    const [changeSubjectName, showChangeSubjectName] = useState(false);
    const {url, path} = useRouteMatch();
    const [hasError, displayError] = useState(false);
    const [isLoading, showLoader] = useState(false);
    const [patchVideo, setPatchVideo] = useState(null);
    const [patchAssignment, setPatchAssignment] = useState(null);
    const history = useHistory();
    const location = useLocation();
    //video refs
    const videoTitle = createRef();
    const videoDescription = createRef();
    const videoUrl = createRef();  

    //subjectName refs
    const newSubjectName = createRef();


    async function addVideo(e) {
        e.preventDefault();
        showLoader(true);
        try{
            const newVideo = {
                title: videoTitle.current.value,
                description: (videoDescription.current && videoDescription.current.value) || '',
                url: videoUrl.current.value,
                active: false
            };
            const res = await axios.post('/course/subject/video', {
                courseId: courseDetails.courseId,
                subjectId: subjectDetails.subjectId,
                video : newVideo
            })
            if(subjectDetails.videos){
                subjectDetails.videos.push({
                    ...newVideo,
                    ...res.data.video
                })
            } else {
                subjectDetails.videos = [{
                    ...newVideo,
                    ...res.data.video
                }];
            } 
            setSubjectDetails({
                ...subjectDetails
            });
            props.setCourseDetails({
                ...props.courseDetails,
                subjects: subjects.map(subject => subject.subjectId === subjectDetails.subjectId? {
                    ...subject,
                    videos: subjectDetails.videos
                }: subject)
            });
            showLoader(false);
            history.replace(location.pathname.substring(0, location.pathname.lastIndexOf('/')));
        } catch(error) {
            displayError(true);
            showLoader(false);
        }
    }

    async function changeSubjectNameRequest(e) {
        e.preventDefault();
        showLoader(true);
        try{
            const newSubject = {
                ...subjectDetails,
                title: newSubjectName.current.value
            };
            await axios.patch('/course/subject', {
                subject: {
                    title: newSubjectName.current.value,
                    subjectId: subjectDetails.subjectId
                },
                courseId: courseDetails.courseId
            });
            showLoader(false);
            setSubjectDetails(newSubject);
            showChangeSubjectName(false);
            props.setCourseDetails({
                ...props.courseDetails,
                subjects: subjects.map(subject => subject.subjectId === subjectDetails.subjectId? newSubject: subject)
            });
        } catch(error) {
            displayError(true);
            showLoader(false);
        }
    }

    async function removeVideo(video) {
        showLoader(true);
        setPatchVideo(video)
        try {
            await axios.delete('/course/'+ courseDetails.courseId+ '/subject/'+ subjectDetails.subjectId + '/video/' + video.videoId);
            subjectDetails.videos.splice(subjectDetails.videos.indexOf(video, 1));
            setSubjectDetails({
                ...subjectDetails
            })
            showLoader(false);
            setPatchVideo(null);
        } catch (error) {
            displayError(true);
            showLoader(false);
            setPatchVideo(null);
        }
    }

    async function toggleVideoActive(video) {
        showLoader(true);
        setPatchVideo(video)
        try {
            await axios.patch('/course/subject/video', {
                courseId: courseDetails.courseId,
                subjectId: subjectDetails.subjectId,
                videoId: video.videoId,
                video : {
                    videoId: video.videoId,
                    active: !video.active
                }
            });
            setSubjectDetails({
                ...subjectDetails,
                videos: subjectDetails.videos.map(videoItem => videoItem.videoId === video.videoId? {
                    ...video,
                    active: !video.active
                }: videoItem)
            })
            showLoader(false);
            setPatchVideo(null);
        } catch (error) {
            displayError(true);
            showLoader(false);
            setPatchVideo(null);
        }
    }

    async function uploadNewAssignment(e) {
        e.preventDefault();
        showLoader(true);
        const fileURL = document.getElementById('assignment-link').value;
            try{
                const newAssign = {
                    title: document.getElementById('assignment-title').value,
                    url: fileURL,
                };
                const res = await axios.post('/course/subject/assignment', {
                    courseId: courseDetails.courseId,
                    subjectId: subjectDetails.subjectId,
                    assignment: newAssign
                })
                if(subjectDetails.assignments){
                    subjectDetails.assignments.push({
                        ...newAssign,
                        ...res.data.assignment
                    })
                } else {
                    subjectDetails.assignments = [{
                        ...newAssign,
                        ...res.data.assignment
                    }];
                } 
                setSubjectDetails({
                    ...subjectDetails
                });
                props.setCourseDetails({
                    ...props.courseDetails,
                    subjects: subjects.map(subject => subject.subjectId === subjectDetails.subjectId? {
                        ...subject,
                        assignments: subjectDetails.assignments
                    }: subject)
                });
                showLoader(false);
                history.replace(location.pathname.substring(0, location.pathname.lastIndexOf('/')));
            } catch(error) {
                displayError(true);
                showLoader(false);
            }
    }

    function getVideoPosters() {
        const arr = [];
        subjectDetails.videos.forEach(video => {
            arr.push(
            <div className="col-sm-6 col-md-4 col-lg-3 d-flex" style={{padding:0}}>
                <div className="card m-2 p-2" style={{position: 'relative'}}>
                    { isLoading && patchVideo === video ? <SpinnerComponent/>: ''}
                    {/* <img src={videoThumb} style={{maxHeight: '150px', width: '100%'}}alt={"image-for-"+video.title}/> */}
                    <div className="video-actions d-flex flex-column justify-content-center align-items-center p-2 f-1">
                        <h5>{video.title}</h5>
                        <p>{video.description? video.description: ''}</p>
                        <div className="mt-auto">
                            <p><strong>Status: </strong><span className={"badge "+(video.active? "badge-success": "badge-danger")}>{video.active? 'Activated': 'Deactivated'}</span></p>
                            <p><strong>Actions: </strong>
                            <button title="Remove" className="btn btn-danger btn-circle" onClick={(e) => removeVideo(video)}>
                            <svg className="bi bi-trash-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M2.5 1a1 1 0 00-1 1v1a1 1 0 001 1H3v9a2 2 0 002 2h6a2 2 0 002-2V4h.5a1 1 0 001-1V2a1 1 0 00-1-1H10a1 1 0 00-1-1H7a1 1 0 00-1 1H2.5zm3 4a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7a.5.5 0 01.5-.5zM8 5a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7A.5.5 0 018 5zm3 .5a.5.5 0 00-1 0v7a.5.5 0 001 0v-7z" clipRule="evenodd"/>
                            </svg></button>&nbsp;&nbsp;
                            <button title={video.active? 'Deactivate': 'Activate'} className={"btn btn-circle " + (video.active? 'btn-danger': 'btn-success')} onClick={() => toggleVideoActive(video)}>
                                <svg className="bi bi-power" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M5.578 4.437a5 5 0 104.922.044l.5-.866a6 6 0 11-5.908-.053l.486.875z" clipRule="evenodd"/>
                                    <path fillRule="evenodd" d="M7.5 8V1h1v7h-1z" clipRule="evenodd"/>
                                </svg>
                            </button></p>
                        </div>
                    </div>
                </div>
            </div>
            )
        });
        return arr;
    }

    async function removeAssignment(assignment, index) {
        showLoader(true);
        setPatchAssignment(assignment)
        try {
            await axios.delete('/course/'+ courseDetails.courseId+ '/subject/'+ subjectDetails.subjectId + '/assignment/' + assignment.assignmentId);
            subjectDetails.assignments.splice(subjectDetails.assignments.indexOf(assignment, 1));
            setSubjectDetails({
                ...subjectDetails
            })
        } catch (error) {
            displayError(true);
        } finally {
            showLoader(false);
            setPatchAssignment(null);
        }
    }

    function getAssignments() {
        const arr = [];
        subjectDetails.assignments.forEach((assignment, index) => {
            arr.push(
            <div className="col-sm-6 col-md-4 col-lg-3" style={{padding:0}}>
                <div className="card m-2">
                    { isLoading && patchAssignment === assignment ? <SpinnerComponent/>: ''}
                    <div className="d-flex flex-column justify-content-center align-items-center p-2">
                        <h5>{assignment.title}</h5>
                        <button className="btn btn-danger" onClick={e => removeAssignment(assignment, index)}>
                        <svg className="bi bi-trash-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M2.5 1a1 1 0 00-1 1v1a1 1 0 001 1H3v9a2 2 0 002 2h6a2 2 0 002-2V4h.5a1 1 0 001-1V2a1 1 0 00-1-1H10a1 1 0 00-1-1H7a1 1 0 00-1 1H2.5zm3 4a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7a.5.5 0 01.5-.5zM8 5a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7A.5.5 0 018 5zm3 .5a.5.5 0 00-1 0v7a.5.5 0 001 0v-7z" clipRule="evenodd"/>
                        </svg>&nbsp;Remove</button>
                    </div>
                </div>
            </div>
            )
        });
        return arr;
    }

    
     return (
         <>
            {
                !subjectDetails? 'Subject Not Found! Please try with a valid subject' :
                [   
                    [
                        <h2>{subjectDetails.title}</h2>
                    ],
                    [
                    <Switch>
                        <Route path= {path+'/add-new-video'}>
                            <form>
                                <div className="form-group" style={{position: "relative"}}>
                                {hasError? <ErrorComponent />: ''}
                                {isLoading? <SpinnerComponent opacity="0"/>: ''}                                
                                <label for="video-title">Video Title</label>
                                <input type="text" ref={videoTitle} className="form-control" name="videoTitle" id="video-title" aria-describedby="emailHelp" required placeholder="Enter Video Title" />
                                </div>
                                <div className="form-group">
                                <label for="video-description">Video Description</label>
                                <textarea rows="2" ref={videoDescription} className="form-control" name="videoDescription" id="video-description" defaultValue='' placeholder="Video Description" />
                                </div>
                                <div className="form-group">
                                <label for="video-file-url">Video URL</label>
                                <input type="text" ref={videoUrl} className="form-control" name="videoFileUrl" placeholder="Video URL" id="video-file-url" required />
                                </div>
                                <button type="submit" className="btn btn-primary" onClick={e => addVideo(e)}>Add Video</button>
                            </form> 
                        </Route>
                        <Route path= {path+'/add-new-assignment'}>
                            <form>
                                <div className="form-group" style={{position: "relative"}}>
                                {hasError? <ErrorComponent />: ''}
                                {isLoading? <SpinnerComponent opacity="0" />: ''}
                                <label for="video-title">Assignment Title</label>
                                <input type="text" className="form-control" id="assignment-title" name="assignmentTitle" aria-describedby="emailHelp" required placeholder="Enter Assignment Title" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" name="assignmentFile" id="assignment-link" required placeholder="Enter Assignment URL"/>
                                </div>
                                <button type="submit" className="btn btn-primary" onClick={e => uploadNewAssignment(e)}>Add Assignment</button>
                            </form>
                        </Route>
                        <Route exact path={path}>
                            <div className="edit-subject-container" style={{position: 'relative'}}>
                                
                                <div className="btn-group row p-2" >
                                    <Link to = {url+'/add-new-video'}>
                                        <button className="btn btn-success mt-3" onClick={_ => displayError(false)}>
                                        <svg className="bi bi-cloud-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M3.5 13a3.5 3.5 0 11.59-6.95 5.002 5.002 0 119.804 1.98A2.5 2.5 0 0113.5 13h-10z" clipRule="evenodd"/>
                                        </svg>&nbsp;Upload New Video</button>
                                    </Link>&nbsp;&nbsp;
                                    <Link to = {url+'/add-new-assignment'}>
                                        <button className="btn btn-success mt-3" onClick={_ => displayError(false)}>
                                        <svg className="bi bi-cloud-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M3.5 13a3.5 3.5 0 11.59-6.95 5.002 5.002 0 119.804 1.98A2.5 2.5 0 0113.5 13h-10z" clipRule="evenodd"/>
                                        </svg>&nbsp;Upload New Assignment</button>
                                    </Link>&nbsp;&nbsp;
                                    <button className="btn btn-success  mt-3" onClick = { _ => showChangeSubjectName(true)}>
                                    <svg className="bi bi-pencil-square" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.502 1.94a.5.5 0 010 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 002.5 15h11a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H9a.5.5 0 000-1H2.5A1.5 1.5 0 001 2.5v11z" clipRule="evenodd"/>
                                    </svg>
                                    &nbsp;Change Subject Name</button>
                                </div>
                                {
                                    changeSubjectName ?
                                    <div className="form-group my-3 " style={{position: "relative"}}>
                                        {hasError? <ErrorComponent />: ''}
                                        {isLoading? <SpinnerComponent opacity="0"/>: ''}
                                        <label for="exampleInputEmail1">Subject Name</label>
                                        <input type="text" ref={newSubjectName} className="form-control" id="subject-name" placeholder="Enter Subject Name" />
                                        <button type="button" className="btn btn-primary mt-2" onClick={e => changeSubjectNameRequest(e)}>Change</button>
                                        <button type="button" className="btn btn-secondary mt-2 mx-2" onClick={e => showChangeSubjectName(false)}>Cancel</button>
                                    </div>: ''
                                }
                                <h2 className="mt-2">Videos</h2>
                                <div className="row video-row">
                                    {subjectDetails.videos && subjectDetails.videos.length ?  getVideoPosters() : <p className="px-3">Sorry! there are no videos to display</p>}
                                </div>
                                <h2 className="mt-3">Assignments</h2>
                                <div className="row assignment-row">
                                    {subjectDetails.assignments && subjectDetails.assignments.length ? getAssignments(): <p className="px-3">Sorry! there are no assignments to display</p>}
                                </div>
                            </div>
                        </Route>
                        <Route path="*">
                            <Redirect to="/admin/courses"></Redirect>
                        </Route>
                    </Switch>
                ]
            ]
            }
         </>
    )
 }
 