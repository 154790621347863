import React from "react";
import { Provider } from "react-redux";
import { rootStore } from './redux/rootStore';
import MainComponent from "./components/MainComponent";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router } from "react-router-dom";
import axios from 'axios';
axios.defaults.baseURL = '/api';
export default function App() {
  return (
    <>
    <Provider store={rootStore}>
      <Router>
        <MainComponent />
      </Router>
    </Provider>
    </>
  );
}
