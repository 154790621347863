import React from 'react'
import SideNav from './SideNav'

export default function AdminHomePageComponent() {
    return (
        <div  className="d-flex admin-container" style={{backgroundColor: '#fcfcfc', position: 'relative', flex: '1'}}>
            <SideNav/>
        </div>
    )
}
