import React, { Component } from 'react'
import './css/login-component.scss';
import { Switch, Route, Redirect } from 'react-router-dom';
import  LogInForm  from './LogInForm';
import { RegistrationForm } from './RegistrationForm';
import ForgotPassword from './ForgotPassword';
import VerifyOtp from './VerifyOtp';

export default class LoginAndRegistrationComponent extends Component {
    render() {
        return (
            <>
                <div className="d-flex flex-column" style={{flex: '1 1 auto'}}>
                    <div className="container-fluid login-container" style={{position: 'relative', flex: '1', zIndex: '2000'}}>
                        <div style={{flexBasis: '100%', height: '100%', maxHeight: '100%', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                            <div className="bg-gradient-primary" style={{height: '50%'}}>
                            </div>
                            <div className="separator separator-bottom separator-skew zindex-100" style={{position: 'absolute', top: '50%', left: 0, right: 0, bottom: '50%', transform: 'translateY(-99%)'}}>
                                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
                                </svg>
                            </div>
                            <div className="bg-default" style={{height: '50%'}}>
                            </div>
                        </div>
                        <div className="login-wrapper">
                            <div className="row">
                                <div className="col-sm-9 col-md-7 col-lg-5 m-auto">
                                    <div className="card card-signin my-5">
                                        <div className="card-body" style={{position: 'relative'}}>
                                            <Switch>
                                                <Route exact path="/user/login">
                                                    <LogInForm/>
                                                </Route>
                                                <Route path = {'/user/verifyNow/:userId'} component={VerifyOtp}></Route>
                                                <Route exact path="/user/register">
                                                    <RegistrationForm />
                                                </Route>
                                                <Route exact path="/user/forgot-password">
                                                    <ForgotPassword />
                                                </Route>
                                                <Route path="*">
                                                    <Redirect to="/"/>
                                                </Route>
                                            </Switch>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
