import React from 'react'

export default function SpinnerComponent(props) {
    return (
        <div className="text-center spinner-wrapper" style={{backgroundColor: 'rgba(255,255,255,'+ (props.opacity? props.opacity: 0.6) + ')'}}>
            <div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}
