import React, { useEffect, useState } from 'react'
import { Link, Switch, Route, Redirect, useHistory } from 'react-router-dom'
import LoginAndRegistrationComponent from './LoginAndRegistration'
import CourseDetailPageComponent from './CoursePage';
import HomeComponent  from './HomeComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/font-awesome.min.css';
import '../assets/css/nucleo.css';
import '../assets/css/argon.min.css';
import '../App.scss';
import { connect } from 'react-redux'
import AdminHomePageComponent from './admin/HomePage'
import CoursesHomepage from './CoursesHomepage'
import { ADMIN, USER, REDIRECT_TO } from '../redux/constants'
import { setLoggedIn, setAccountType, setUserData, setUserCourses } from '../redux/actions'
import UserHomepage from './UserHomepage';
import FooterComponent from './FooterComponent';
import axios from 'axios';
import logo from '../assets/images/logo.png'
import ShowImageComponent from './ShowImageComponent';
import './css/media-queries.scss';
import $ from 'jquery';

export function MainComponent(props) {
    const [callPending, setCallState] = useState(true);
    const {push} = useHistory();
    const {setLoggedInMain, setUserDataMain, setAccountTypeMain, userCoursesFetched} = props;
    function handleLogInAction() {
        if(props.userRole === USER) {
            if(!!props.afterLogInAction && props.afterLogInAction.actionType === REDIRECT_TO) {
                return <Redirect to = {props.afterLogInAction.payload} key="buy-course" />
            } else {
                return <Redirect to = "/dashboard" key="dashboard"/>
            }
        } else if(props.userRole === ADMIN){
            return <Redirect to = "/admin" key="admin-homepage"/>
        } else {
            return <Redirect to = "/" key="/"/>
        }
    }

    useEffect(() => {
        async function checkLoggedInState() {
            try {
                const res = await axios.post('/user/login', {});
                const obj = res.data.userDetails || res.data.userObj;
                if(obj.role === 'user') {
                   const res2 =await axios.get('/user/courses');
                   setUserDataMain({
                        ...obj,
                        courses: res2.data.filter(course => obj.courses.includes(course.courseId)),
                        courseHistory: res2.data.filter(course => !!obj.courseHistory?.find(c => c.courseId === course.courseId)).map(course => {
                            return {
                                ...course,
                                ...obj.courseHistory.find(c => c.courseId === course.courseId)
                            }
                        }),
                   });
                   userCoursesFetched(true);
                } else {
                    setUserDataMain(obj);
                }
                setAccountTypeMain(obj.role === 'admin'? ADMIN: USER);
                setLoggedInMain(true);
            } catch(error) {
                if(error.response && error.response.status === 401){
                    push('/user/register/verifyNow');
                }
            }
            setCallState(false);
        }
        checkLoggedInState();
    }, [push, setLoggedInMain, setAccountTypeMain, setUserDataMain, userCoursesFetched]);

    useEffect(() => {
        var navMain = $("#navbar-collapse");
        $(function(){ 
            navMain.on("click", ".nav-link", clickHandler);
        });

        function clickHandler() {
            console.log('clicked');
            navMain.toggleClass('show');
        };

        return () => {
            navMain.off('click', '.nav-link', clickHandler)
        }
    }, [callPending]);

    async function handleLogOutAction() {
        try{
            await axios.get('/user/logout');
            setLoggedInMain(false);
            setUserDataMain(null);
            userCoursesFetched(false);
            setAccountTypeMain(null);
        } catch(error) {
        }
    }

    return (
        <>  
                {
                    !callPending ? 
                    <>
                    {/* <nav className="navbar navbar-expand-md navbar-light bg-light" style={{position: 'sticky', top: 0,zIndex:'1025'}}>
                    <span className="navbar-nav d-flex">
                    <Link to="/">
                        <span className="navbar-brand d-flex align-items-center"><img src={logo} alt="logo" style={{width: '35px', height: '32px'}}></img>Quanta Institute</span>
                    </Link>
                    </span>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarColor01">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link to="/courses">
                                <span className="nav-link" id="online-courses-btn" role="button" >
                                    Online Courses
                                </span>
                            </Link>
                        </li>
                        {
                            props.isLoggedIn && props.userRole === USER ? 
                                <li className="nav-item">
                                    <Link to="/dashboard">
                                        <span className="nav-link">My Homepage</span>
                                    </Link> 
                                </li>: ''
                        }
                        {
                            props.isLoggedIn && props.userRole === ADMIN ? 
                                <li className="nav-item">
                                    <Link to="/admin">
                                        <span className="nav-link">Admin Homepage</span>
                                    </Link> 
                                </li>: ''
                        }
                        {
                            props.isLoggedIn ? (
                                <>
                                <li className="nav-item" onClick={(e) => handleLogOutAction()} style={{cursor: 'pointer'}}><span className="nav-link">Logout</span></li>
                                </>
                            ) :  (
                                <>
                                <li className="nav-item"><Link to ="/user/register"><span className="nav-link">Sign Up </span></Link></li>
                                <li className="nav-item"><Link to ="/user/login"><span className="nav-link">Login </span></Link></li>
                                </>
                            )
                        }
                    </ul>
                    </div>
                </nav> */}
                <nav id="navbar-main" className="navbar navbar-horizontal bg-gradient-primary navbar-main navbar-expand-lg navbar-dark">
                <div className="container">
                    <Link to="/">
                        <span className="navbar-brand" style={{textTransform: 'none', color: 'white', fontWeight: '400', fontSize: '18px',paddingTop: '.2625rem', display: 'flex', alignItems: 'center'}}>
                            <img src={logo} alt=""/><span className="nav-link-inner--text">&nbsp;&nbsp;Gamma Maths Academy</span>
                        </span>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse navbar-custom-collapse collapse" id="navbar-collapse" style={{zIndex: 2001}}>
                        <div className="navbar-collapse-header">
                        <div className="row">
                            <div className="col-9 collapse-brand">
                            <Link to="/">
                                <span className="nav-link" style={{padding: 0}}>
                                    <img src={logo} alt=""/><span className="nav-link-inner--text">Gamma Maths Academy</span>
                                </span>
                            </Link>
                            </div>
                            <div className="col-3 collapse-close">
                            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                                <span></span>
                                <span></span>
                            </button>
                            </div>
                        </div>
                        </div>
                        {
                            !props.isLoggedIn && <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <Link to="/user/login">
                                        <span href="login.html" className="nav-link">
                                            <span className="nav-link-inner--text">Login</span>
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/user/register">
                                        <span className="nav-link">
                                            <span className="nav-link-inner--text">Register</span>
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/courses">
                                        <span className="nav-link" id="online-courses-btn" role="button" >
                                            <span className="nav-link-inner--text">Our Courses</span>
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        }
                        {!props.isLoggedIn ? <hr className="d-lg-none" />: ''}
                        
                        <ul className="navbar-nav align-items-lg-center ml-lg-auto">
                        {
                            props.isLoggedIn && <li className="nav-item">
                                <Link to="/courses">
                                    <span className="nav-link" id="online-courses-btn" role="button" >
                                        Our Courses
                                    </span>
                                </Link>
                            </li>
                        }
                        {
                            props.isLoggedIn && props.userRole === USER ? 
                                <li className="nav-item">
                                    <Link to="/dashboard">
                                        <span className="nav-link">My Homepage</span>
                                    </Link> 
                                </li>: ''
                        }
                        {
                            props.isLoggedIn && props.userRole === ADMIN ? 
                                <li className="nav-item">
                                    <Link to="/admin">
                                        <span className="nav-link">Admin Homepage</span>
                                    </Link> 
                                </li>: ''
                        }
                        {
                            props.isLoggedIn ? (
                                <>
                                <li className="nav-item" onClick={(e) => handleLogOutAction()} style={{cursor: 'pointer'}}><span className="nav-link">Logout</span></li>
                                </>
                            ) :  ''
                        }
                        {
                        !props.isLoggedIn &&
                        <>
                            <li className="nav-item">
                                <a className="nav-link nav-link-icon" href="https://www.facebook.com/gammamaths/?rf=656436541199253" target="_blank" rel="noopener noreferrer" data-toggle="tooltip" data-original-title="Like us on Facebook">
                                <i className="fab fa-facebook-square"></i>
                                <span className="nav-link-inner--text d-lg-none">Facebook</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-icon" href="https://www.instagram.com/gammamaths/" target="_blank" data-toggle="tooltip" rel="noopener noreferrer" data-original-title="Follow us on Instagram">
                                <i className="fab fa-instagram"></i>
                                <span className="nav-link-inner--text d-lg-none">Instagram</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-icon" href="https://twitter.com/GammaMaths" target="_blank" data-toggle="tooltip" rel="noopener noreferrer" data-original-title="Follow us on Twitter">
                                <i className="fab fa-twitter-square"></i>
                                <span className="nav-link-inner--text d-lg-none">Twitter</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-link-icon" href="https://www.youtube.com/channel/UC_gHaMeyMFAcKPVrg5ygTbAs" target="_blank" data-toggle="tooltip"rel="noopener noreferrer" data-original-title="Star us on Github">
                                <i className="fab fa-youtube"></i>
                                <span className="nav-link-inner--text d-lg-none">YouTube</span>
                                </a>
                            </li>
                        </>
                        }
                        </ul>
                    </div>
                    </div>
                </nav>
                <Switch>
                    <Route path="/user">
                        {
                            props.isLoggedIn? [handleLogInAction()] : <LoginAndRegistrationComponent/>
                        }
                    </Route>
                    <Route path="/admin">
                    {
                        props.userRole === ADMIN ? <AdminHomePageComponent /> : 
                        props.userRole === USER ? <Redirect to="/dashboard"/>: <Redirect to="/" />
                    }
                    </Route>
                    <Route path="/dashboard">
                    {
                        props.isLoggedIn &&  props.userRole === USER ? <UserHomepage /> : <Redirect to="/" />
                    }
                    </Route>
                    <Route exact path="/courses">
                        <CoursesHomepage/>
                    </Route>
                    <Route path="/courses/:id">
                        <CourseDetailPageComponent />
                    </Route>
                    <Route path="/image/:type?/:id" component={ShowImageComponent}>
                    </Route>
                    <Route exact path="/">
                        <HomeComponent/>
                    </Route>
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
                <FooterComponent/>
                </>
            : ''}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.logInState,
        userRole: state.accountType,
        afterLogInAction: state.afterLogInAction
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoggedInMain: (isLoggedIn) => dispatch(setLoggedIn(isLoggedIn)),
        setAccountTypeMain: (accountType) => dispatch(setAccountType(accountType)),
        setUserDataMain: (data) => (dispatch(setUserData(data))),
        userCoursesFetched: (fetched) => dispatch(setUserCourses(fetched)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainComponent)
