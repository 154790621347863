import React, { Component } from 'react'
import DataTable from 'react-data-table-component';
import { Link, withRouter, Switch, Route, Redirect } from 'react-router-dom';
import CourseAddAndEdit from './CourseAddAndEdit';
import axios from 'axios';
import SpinnerComponent from '../common/SpinnerComponent';
import ErrorComponent from '../common/ErrorComponent';
import { formatDate } from '../../utils/util';
import CourseEditMainPage from './CourseEditMainPage';

class Courses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            gettingCourse: true,
            isUpdating: false,
            showError: false
        }
        this.columns = [
            {
                name: 'Course Name',
                cell: (row) => <span title = {row.title}>{row.title}</span>
            },
            {
                name: 'Start Date',
                selector: 'startDate',
            },
            {
                name: 'End Date',
                selector: 'endDate',
            },
            {
                name: 'Status',
                cell : (row) => {
                    return (
                    <span className={"badge "+(row.active? "badge-success": "badge-danger")}>{row.active? 'Activated': 'Deactivated'}</span>
                )}
            },
            {
                name: 'Actions',
                cell: (row) => (
                    <>
                    <Link to ={{
                        pathname: props.match.url+ '/edit-course/'+row.courseId,
                        state: {
                            courseDetails: row
                        }
                    }}>
                        <button title="Edit" className="btn btn-primary btn-circle edit-btn">
                            <svg className="bi bi-pencil" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M11.293 1.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z" clipRule="evenodd"/>
                                <path fillRule=    "evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 00.5.5H4v.5a.5.5 0 00.5.5H5v.5a.5.5 0 00.5.5H6v-1.5a.5.5 0 00-.5-.5H5v-.5a.5.5 0 00-.5-.5H3z" clipRule="evenodd"/>
                            </svg>
                        </button>
                    </Link>
                    <button title={row.active? 'Deactivate': 'Activate'} className={"btn btn-circle " + (row.active? 'btn-danger': 'btn-success')} onClick={() => this.toggleActivation(row.courseId, row.active)}>
                        <svg className="bi bi-power" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M5.578 4.437a5 5 0 104.922.044l.5-.866a6 6 0 11-5.908-.053l.486.875z" clipRule="evenodd"/>
                            <path fillRule="evenodd" d="M7.5 8V1h1v7h-1z" clipRule="evenodd"/>
                        </svg>
                    </button>
                    {/* <button title="Create a new Batch" className={"btn btn-circle btn-secondary"} onClick={() => this.editItem(row.courseId)}>
                        <svg fill="currentColor"xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" version="1.1">
                            <path d="M 2.667969 4.5 C 2.667969 2.753906 4.085938 1.332031 5.832031 1.332031 L 11.921875 1.332031 C 11.703125 0.566406 11.003906 0 10.167969 0 L 2.5 0 C 1.488281 0 0.667969 0.820312 0.667969 1.832031 L 0.667969 12.167969 C 0.667969 13.179688 1.488281 14 2.5 14 L 2.667969 14 Z M 2.667969 4.5 "/>
                            <path d="M 13.5 2.667969 L 5.832031 2.667969 C 4.820312 2.667969 4 3.488281 4 4.5 L 4 14.167969 C 4 15.179688 4.820312 16 5.832031 16 L 13.5 16 C 14.511719 16 15.332031 15.179688 15.332031 14.167969 L 15.332031 4.5 C 15.332031 3.488281 14.511719 2.667969 13.5 2.667969 Z M 12.167969 14 L 7.167969 14 C 6.890625 14 6.667969 13.777344 6.667969 13.5 C 6.667969 13.222656 6.890625 13 7.167969 13 L 12.167969 13 C 12.441406 13 12.667969 13.222656 12.667969 13.5 C 12.667969 13.777344 12.441406 14 12.167969 14 Z M 12.167969 11.332031 L 7.167969 11.332031 C 6.890625 11.332031 6.667969 11.109375 6.667969 10.832031 C 6.667969 10.558594 6.890625 10.332031 7.167969 10.332031 L 12.167969 10.332031 C 12.441406 10.332031 12.667969 10.558594 12.667969 10.832031 C 12.667969 11.109375 12.441406 11.332031 12.167969 11.332031 Z M 12.167969 9 L 7.167969 9 C 6.890625 9 6.667969 8.777344 6.667969 8.5 C 6.667969 8.222656 6.890625 8 7.167969 8 L 12.167969 8 C 12.441406 8 12.667969 8.222656 12.667969 8.5 C 12.667969 8.777344 12.441406 9 12.167969 9 Z M 12.167969 6.332031 L 7.167969 6.332031 C 6.890625 6.332031 6.667969 6.109375 6.667969 5.832031 C 6.667969 5.558594 6.890625 5.332031 7.167969 5.332031 L 12.167969 5.332031 C 12.441406 5.332031 12.667969 5.558594 12.667969 5.832031 C 12.667969 6.109375 12.441406 6.332031 12.167969 6.332031 Z M 12.167969 6.332031 "/>
                        </svg>
                    </button> */}
                    </>
                ),
            },
        ];

        this.handleChange = this.handleChange.bind(this);
        this.setCourses = this.setCourses.bind(this);
    }
    
    setCourses(courses) {
        this.setState(prevState => {
            return {
                ...prevState,
                courses: courses
            }
        });
    }

    async toggleActivation(id, active) {
        this.setState(prevState => {
            return {
                ...prevState,
                isUpdating: true,
                hasError: false
            }
        })
        try {
            await axios.patch('/course', {
                courseId: id,
                active: !active
            });
            const newCourseArray = this.state.courses.map(course => {
                if(course.courseId !== id) {
                    return course;
                } else {
                    return {
                        ...course,
                        active: !active
                    }
                }
            })
            this.setState({
                courses: newCourseArray,
                isUpdating: false,
                hasError: false
            })
        } catch(error) {
            this.setState(prevState => {
            return {
                ...prevState,
                isUpdating: false,
                hasError: true
            }
        })
        }
    }

    async componentDidMount() {
        try{
            const res = await axios.get('/admin/courses');
            this.setState(prevState => {
                return {
                    courses: res.data.map(course => {
                        return {
                            ...course,
                            startDate: formatDate(course.startDate),
                            endDate: formatDate(course.endDate)
                        }
                    }),
                    showError: false,
                    gettingCourse: false
                }
            });
        } catch(error) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    showError: true,
                    gettingCourse: false
                }
            })
        }
        
    }
    handleChange(data) {
    }
    render() {
        return (
                <>
                { this.state.gettingCourse? <SpinnerComponent />: 
                    <Switch>
                        <Route exact path={this.props.match.path + '/add-new-course'}>
                            <CourseAddAndEdit courses= {this.state.courses} setCourses={this.setCourses}/>
                        </Route>
                        <Route path={this.props.match.path+'/edit-course/:courseId'} render={(props) => <CourseEditMainPage {...props} courses = {this.state.courses} setCourses={this.setCourses}/>} />
                        <Route exact path={this.props.match.path}>
                            <div className="row data-table-container courses-data-container justify-content-center" >
                                <div className="col-12 col-md-10 data-table-wrapper p-0" style={{position: "relative"}}>
                                <DataTable
                                    title="Manage Courses"
                                    columns={this.columns}
                                    subHeader={true}
                                    subHeaderComponent={<div>
                                        <CourseAddBtn url={this.props.match.url}/>
                                        <br/>
                                        {this.state.showError? <div style={{marginLeft: '-10px'}}><ErrorComponent /></div>: ''}
                                    </div>}
                                    subHeaderAlign={'left'}
                                    data={this.state.courses}
                                    keyField="courseId"
                                    pagination = {true}
                                    paginationPerPage = {7}
                                    paginationRowsPerPageOptions = {[5, 7, 10, 15, 20, 50]}
                                />
                                {this.state.isUpdating ? <SpinnerComponent /> : ''}
                                </div>
                            </div>
                        </Route>
                        <Route path = "*">
                            <Redirect to="/admin" />
                        </Route>
                    </Switch>
                }
            </>
        )
    }
}

export function CourseAddBtn(props) {
    return (
        <Link to={props.url + '/add-new-course'}>
            <button className="btn btn-success" style={{marginLeft: '-10px'}}>
            <svg className="bi bi-plus-square-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm6.5 4a.5.5 0 00-1 0v3.5H4a.5.5 0 000 1h3.5V12a.5.5 0 001 0V8.5H12a.5.5 0 000-1H8.5V4z" clipRule="evenodd"/>
            </svg> &nbsp;Add New Course</button>
        </Link>
    )   
}

export default withRouter(Courses);