import { SET_LOGGED_IN, SET_ACCOUNT_TYPE, SET_LOGIN_ACTION, SET_USER_DATA, SET_COURSE_DATA, SET_USER_COURSES } from "./constants";
import { combineReducers } from "redux";

const logInInitialState = false;
const accountTypeInitialState = '';

const logInState = (state = logInInitialState, action) => {
    switch (action.type) {
        case SET_LOGGED_IN: {
            return action.payload
        }
        default: return state;
    }
}

const accountType = (state = accountTypeInitialState, action) => {
    switch (action.type) {
        case SET_ACCOUNT_TYPE: {
            return action.payload;
        }
        default: return state;
    }
}

const afterLogInAction = (state = null, action) => {
    switch (action.type) {
        case SET_LOGIN_ACTION: {
            return {
                actionType: action.actionType,
                payload: action.payload
            }
        }
        default: return state;
    }
}

const userData = (state = null, action) => {
    switch (action.type) {
        case SET_USER_DATA: {
            return action.payload
        }
        default: return state;
    }
}

const courseData = (state = null, action) => {
    switch (action.type) {
        case SET_COURSE_DATA: {
            return action.payload
        }
        default: return state;
    }
}

const userCoursesSet = (state = null, action) => {
    switch (action.type) {
        case SET_USER_COURSES: {
            return action.payload
        }
        default: return state;
    }
}


export default combineReducers({
    logInState,
    accountType,
    afterLogInAction,
    userData,
    courseData,
    userCoursesSet
});