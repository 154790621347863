import React, { useEffect, useState } from 'react';
import './css/pendingApprovals.scss';
import axios from 'axios';
import SpinnerComponent from '../common/SpinnerComponent';
import ErrorComponent from '../common/ErrorComponent';

export default function PendingApprovals() {
    const [isLoading, setLoadingState] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showModalForApproval, setShowModalForApproval] = useState(true);
    const [modalDataItem, setModalDataItem] = useState(null);
    const [orders, setOrders] = useState(null);
    const [showModalSpinner, setShowModalSpinner] = useState(false);
    const [showModalError, setshowModalError] = useState(false);
    useEffect(() => {
        async function fetchOrders() {
            try {
                const res = await axios.get('/orders');
                setOrders(res.data.orders);
                setLoadingState(false);
            } catch(err) {
            }
        }
        fetchOrders();

        return () => {
            setOrders(null);
            setLoadingState(false);
        }
    }, []);
    
    function getCards() {
        const cards = [];
        orders.forEach((dataItem, index) => {
            cards.push(
                <div className="col-sm-6 col-md-4 col-lg-3 card-wrapper mt-2 d-flex" key={dataItem.orderId}>
                    <div className="card ml-2 mt-2">
                        <div className="card-image-top">
                            <a href onClick={(e) => dowloadImage(e, dataItem.receipt)}>
                                <img src={dataItem.receipt} alt="Screenshot not valid Screenshot not valid Screenshot not valid Screenshot not valid Screenshot not valid"/>
                                <button className="btn btn-dark">Tap To View</button>
                            </a>
                        </div>
                        <div className="card-body d-flex flex-column">
                            <h4>{dataItem?.userObj[0]?.username}</h4>
                            <h5>{dataItem.courseObj.title}</h5>
                            <h5>₹{dataItem.amount}</h5>
                            <h5>{dataItem?.userObj[0]?.phone}</h5>
                            <div className="card-btn-container d-flex justify-content-center align-items-center mt-auto">
                                <button type="button" className="btn btn-success m-2" onClick={_ => showApprovalModalFor(dataItem)}>{dataItem.state === 'renew'? 'Renew': 'Approve'}</button>
                                <button type="button" className="btn btn-danger m-2" onClick={_ => showRejectionModalFor(dataItem)}>Reject</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return cards;

    }

    async function dowloadImage(event, imageSrc) {
        event.preventDefault();
        const a = document.createElement("a");
        // a.href = await (imageSrc);
        a.href = imageSrc;
        a.download = "receipt";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    
    // async function toDataURL(url) {
    //     return fetch(url).then((response) => {
    //         return response.blob();
    //     }).then(blob => {
    //         return URL.createObjectURL(blob);
    //     });
    // }

    function showApprovalModalFor(dataItem) {
        setShowModal(true);
        setModalDataItem(dataItem);
        setShowModalForApproval(true);
    }
    
    function showRejectionModalFor(dataItem) {
        setShowModal(true);
        setModalDataItem(dataItem);
        setShowModalForApproval(false);
    }
    async function sendConfirmRequest() {
        setShowModalSpinner(true);
        setshowModalError(false);
        try {
            await axios.patch('/course/order', {
                orderId: modalDataItem.orderId,
                order: {
                    state: showModalForApproval? 'approved': 'rejected'
                }
            });
            orders.splice(orders.indexOf(modalDataItem), 1);
            setOrders([...orders]);
            setShowModalSpinner(false);
            setShowModal(false);
        } catch(err) {
            setShowModalSpinner(false);
            setshowModalError(true);
        }
    }
    return (
        <>
            <div className="container approvals-container" style={{height: '100%'}}>
                <h2 className="mb-3 p-2 text-center">Pending Approvals</h2>
                <div id="cards-container" className="row justify-content-center">
                    {orders && orders.length? <> {getCards()} </>: isLoading? <SpinnerComponent /> : <p>No Pending Approvals!</p>}
                </div>
                {showModal? 
                <div className="buy-course-modal" style={{position: 'fixed'}}>
                    <div className="modal-custom p-4" style={{backgroundColor: '#ffffff', borderRadius: '12px', position: 'relative'}}>
                        {
                            showModalForApproval
                            ? 
                                <h5 className="text-info">Are You Sure You Want To Approve this Request?</h5> 
                            :
                                <h5 className="text-danger">Are You Sure You Want To Reject this Request?</h5> 
                        }
                        <p className="hint text-danger">Note: This action cannot be reversed.</p>
                        {showModalSpinner? <SpinnerComponent />: ''}
                        {showModalError? <ErrorComponent />: ''}
                        <h6>Request Details:</h6>
                        <ul>
                            <li>Student's Name: {modalDataItem?.userObj[0]?.username}</li>
                            <li>Course Applied for: {modalDataItem.courseObj.title}</li>
                            <li>Student's Email: {modalDataItem.email}</li>
                            <li>Student's Phone Number: {modalDataItem?.userObj[0]?.phone}</li>
                            <li>Amount Paid:  ₹{modalDataItem.amount}</li>
                        </ul> 
                        <button className="btn btn-primary" onClick={sendConfirmRequest}>Confirm</button>
                        <button className="btn btn-secondary ml-2" onClick={()=>{setShowModal(false);setshowModalError(false)}}>Cancel</button>
                    </div>
                </div>: ''}
            </div>
        </>
    )
}
