import React, { useReducer, useState } from 'react';
import { checkPhoneNumber, checkPasswords } from '../utils/util';
import { Link, Switch, Route, useHistory, useRouteMatch, Redirect } from 'react-router-dom';
import Axios from 'axios';
import SpinnerComponent from './common/SpinnerComponent';

const initialState = {
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    phoneNo: ''
}

const reducer = (state, action) => {
    switch(action.type) {
        default: return {
            ...state,
            [action.type]: action.value
        }
    }
}

export function RegistrationForm() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();
    const {path} = useRouteMatch();
    const [showLoader, setShowLoader] = useState(false);
    const [errorText, setErrorText] = useState('');
    

    async function checkValidity(event) {
        event.preventDefault();
        console.log('calling');
        if(checkPhoneNumber(state.phone, 'phoneRegistration') && checkPasswords(state.password, state.confirmPassword, 'confirmPassword')) {
          setShowLoader(true);
           try{
             const obj = {...state};
             delete obj['confirmPassword'];
             const res = await Axios.post('/user/signup', obj);
             history.push('/user/verifyNow/' + res.data.userId);
           } catch(error) {
            if(error.response && error.response.status === 400) {
              setErrorText('Email Address/Mobile Number Already exists!');
            }else  {
              setErrorText('Some Error Occured. Please try again');
            }
            setShowLoader(false);
           }
        }
    }


    return (
          <Switch>
            <Route path = {path} exact>
              <form name="registration-form" onSubmit={(e) => checkValidity(e)}>
                {showLoader? <SpinnerComponent/> : ''}
                <small className="d-flex justify-content-center p-3">Register your account</small>
                <div className="input-icons"> 
                  <i className="fa fa-address-book"></i> 
                  <input 
                  type="text"
                  id="username"
                  name="name"
                  className="form-control mt-2"
                  placeholder="Name"
                  required
                  minLength="5"  onChange={(e) => dispatch({type: 'username', value: e.target.value})}/> 
                </div> 
                <div className="input-icons"> 
                  <i className="fa fa-envelope"></i> 
                  <input type="email"
                  id="email"
                  className="form-control mt-2"
                  required
                  placeholder="Email"
                  onChange={(e) => dispatch({type: 'email', value: e.target.value})}/> 
                </div> 
                <div className="input-icons"> 
                  <i className="fa fa-phone"></i> 
                  <input  type="text"
                    id="phoneRegistration"
                    className="form-control mt-2"
                    name="phoneNumber"
                    required
                    minLength="10"
                    onChange={(e) => {dispatch({type: 'phone', value: e.target.value});e.target.setCustomValidity('')}}
                    placeholder="Phone Number"/> 
                </div> 
                <div className="input-icons"> 
                  <i className="fa fa-unlock-alt"></i> 
                  <input  type="password"
                    id="password"
                    className="form-control mt-2"
                    required
                    minLength="8"
                    onChange={(e) => dispatch({type: 'password', value: e.target.value})}
                    placeholder="Password"/> 
                </div> 
                <div className="input-icons"> 
                  <i className="fa fa-unlock-alt"></i> 
                  <input  type="password"
                    id="confirmPassword"
                    className="form-control mt-2"
                    required
                    minLength="8"
                    onChange={(e) => {dispatch({type: 'confirmPassword', value: e.target.value}); e.target.setCustomValidity('')}}
                    placeholder="Confirm Password"/> 
                </div> 
                {errorText? <p className="text-danger">{errorText}</p>: ''}
                <div className="d-flex justify-content-center">
                  <button className="btn btn-lg btn-primary mt-4" type="submit">Register</button>
                </div>
                <div className="text-center">
                  <small className="d-inline-block mt-3">Already have account?&nbsp;<Link to="/user/login"><span>Log In here !</span></Link></small>
                </div>
              </form>
            </Route>
            <Route path = '*'>
              <Redirect to = "/"></Redirect>
            </Route>
          </Switch>
    )
}