import React from 'react';
import styles from './css/sidenav.module.scss';
import './css/sidenav.scss';
import { Switch, Route, useRouteMatch, NavLink, Redirect } from 'react-router-dom';
import PendingApprovals from './PendingApprovals';
import Courses from './Courses';
import ViewStudents from './ViewStudents';

export default function SideNav() {
    let { path, url } = useRouteMatch();

    function toggleSideNav(tgl) {
        if(tgl==='open') {
            document.getElementById('mySideNav').style.display = 'block';
        } else {
            document.getElementById('mySideNav').style.display = 'none';
        }
    }
    return (
        <>
            <div id="mySideNav" className={styles.sideNav + " sideNavAdmin"}>
                <span className="close-btn" onClick={e => toggleSideNav()}>
                    <svg className="bi bi-x" width="25px" height="25px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z" clipRule="evenodd"/>
                        <path fillRule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z" clipRule="evenodd"/>
                    </svg>
                </span>
                <NavLink exact to={`${url}`} activeClassName={styles.sideNavActive}  className={`${styles.sideNavLink}`} >
                    <span>
                    <svg className="bi bi-house-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 3.293l6 6V13.5a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z" clipRule="evenodd"/>
                        <path fillRule="evenodd" d="M7.293 1.5a1 1 0 011.414 0l6.647 6.646a.5.5 0 01-.708.708L8 2.207 1.354 8.854a.5.5 0 11-.708-.708L7.293 1.5z" clipRule="evenodd"/>
                    </svg> &nbsp;
                    Home
                    </span>
                </NavLink>
                <NavLink to={`${url}/courses`} activeClassName={styles.sideNavActive} className={`${styles.sideNavLink}`}>
                <span>
                    <svg className="bi bi-bookmarks-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M2 4a2 2 0 012-2h6a2 2 0 012 2v12l-5-3-5 3V4z" clipRule="evenodd"/>
                        <path d="M14 14l-1-.6V2a1 1 0 00-1-1H4.268A2 2 0 016 0h6a2 2 0 012 2v12z"/>
                    </svg> &nbsp;
                    Manage Courses
                </span>
                </NavLink>
                <NavLink to={`${url}/students`} activeClassName={styles.sideNavActive} className={`${styles.sideNavLink}`}>
                <span>
                    <svg className="bi bi-people-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 100-6 3 3 0 000 6zm-5.784 6A2.238 2.238 0 015 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 005 9c-4 0-5 3-5 4s1 1 1 1h4.216zM4.5 8a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" clipRule="evenodd"/>
                    </svg> &nbsp;
                    View Students
                </span>
                </NavLink>
            </div>
            <span className = "sidenav-toggler" id="sidenav-toggle-btn" onClick={(e) => toggleSideNav('open')}>
                <svg className="bi bi-chevron-double-right" width="2em" height="2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L9.293 8 3.646 2.354a.5.5 0 010-.708z" clipRule="evenodd"/>
                    <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L13.293 8 7.646 2.354a.5.5 0 010-.708z" clipRule="evenodd"/>
                </svg>
            </span>
            <div className = "m-5 admin-page-container" style={{flexBasis: '100%'}}>
                <Switch>
                    <Route path={path} exact>
                        <PendingApprovals/>
                    </Route>
                    <Route path={`${path}/courses`}>
                        <Courses/>
                    </Route>
                    <Route path={`${path}/students`} component = {ViewStudents} />
                    <Route path="*">
                        <Redirect to="/admin"/>
                    </Route>
                </Switch>
            </div>
        </>
    )
}
