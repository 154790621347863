import React, { Component } from 'react';
import VideoPlayer from 'react-video-js-player';
import Axios from 'axios';
import { connect } from 'react-redux';
import { setLoggedIn, setUserData, setUserCourses, setAccountType } from '../../redux/actions';
 
class VideoApp extends Component {
    player = {}
    state = {
        video: {
            src: "",
        }
    }
    interval = null;
    constructor(props) {
      super(props);
      this.checkLoggedIn = this.checkLoggedIn.bind(this);
    }
    

    componentDidMount() {
      if(!this.props.userData?.courses?.find(course => course.courseId === this.props.location.state?.courseId) || !this.props.location.state.url) {
        this.props.history.replace(this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf('/')));
        return false;
      } 
      this.setState(prevState => {
        return {
          video: {
            src:this.props.location.state?.url
          }
        }
      });
      this.interval = setInterval(this.checkLoggedIn, 30000);
    }

    componentWillUnmount() {
      if(this.interval) {
        this.clearLoginInterval();
      }
    }

    async checkLoggedIn() {
      try {
        const res = await Axios.post('/user/login', {});
        if(res.data.userObj.courseHistory?.find(course => course.courseId === this.props.location.state.courseId)) {
          const courseId = this.props.location.state?.courseId;
          this.clearLoginInterval();
          this.props.setUserData({
            ...res.data.userObj,
            courses: this.props.userData.courses.filter(course => course.courseId !== courseId),
            courseHistory: [{
              courseId: courseId,
              message: 'Please pay amount',
              amount: 1000
            }]
          });
          this.props.history.replace('/courses/'+ courseId);
        }
      } catch(error) {
        this.clearLoginInterval();
        this.props.setUserData(null);
        this.props.userCoursesFetched(false);
        this.props.setAccountType(null);
        this.props.setLoggedIn(false);
        this.props.history.replace('/');
      }
    }

    clearLoginInterval() {
      clearInterval(this.interval);
    }
    
 
    onPlayerReady(player){
        this.player = player;
    }
 
    render() {
        return (
          <>
          {
            this.state.video.src?
            <div className="video-player-wrapper">
              {
                this.state.video.src.indexOf('player.vimeo.com') !== -1? 
                <iframe src={this.state.video.src} title="video-player" width="100%" id="video-player" height="420" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>:
                  <VideoPlayer
                  controls={true}
                  src={this.state.video.src}
                  poster={this.state.video.poster}
                  width="720"
                  height="420"
                  onReady={this.onPlayerReady.bind(this)}
                />
              }
            </div>
            : <div><div className="text-danger-container m-4">Video Unavailable. Please try again.</div></div>
         }
         </>
        );
    }
}

const mapStateToProps = (state) => {
  return {
      userData: state.userData
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      setLoggedIn: (isLoggedIn) => dispatch(setLoggedIn(isLoggedIn)),
      setAccountType: (accountType) => dispatch(setAccountType(accountType)),
      setUserData: (data) => (dispatch(setUserData(data))),
      userCoursesFetched: (fetched) => dispatch(setUserCourses(fetched)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VideoApp);