import React, { useReducer, useState } from 'react'
import { Switch, Route, Link } from 'react-router-dom';
import EditProfile from './EditProfile';
import { connect } from 'react-redux';
import test1 from '../assets/images/person.png';

const profileReducer = function(state, action) {
    switch(action.type) {
        case 'RESET_ALL': 
            return action.payload || {}
        default:  return {
            ...state,
            [action.type]: action.payload
        }
    }
}
function UserProfile(props) {
    const {userDetails} = props;
    const [editDetails, dispatch] = useReducer(profileReducer, {});
    const [showAddressForm, setShowAddressForm] = useState(false);
    const [showCourseForm, setShowCourseForm] = useState(false);
    
    async function submitAddressOrCourse(e) {
        e.preventDefault();
        if(showAddressForm) {
            console.log(editDetails, showCourseForm)
        } else {
        }
    }
    return (
        <div className="container edit-profile-container my-5">
            <div className="row">
                <div className="col-md-4">
                    <div className="card">
                        {
                            userDetails? 
                            <div className="card-body p-3" style={{backgroundColor: '#ffffff'}}>
                                <img src={test1} style={{maxWidth: '100px', padding: '5px', maxHeight: '100px', margin: '0 auto', display: 'block', width: '100px', borderRadius: '50%', border: '6px solid rgba(0,0,0,.15)'}} alt=""/>
                                <h2 className="mt-2 mb-0">{userDetails.username}</h2>
                                <h3 className="mb-0 mt-2">{userDetails.email}</h3>
                                <h3 className="mb-0 mt-2">{userDetails.phone}</h3>
                                {/* <div className=" mb-2 py-2 px-4">
                                    <Link to="/dashboard/user-profile/course-form">
                                    <button className="btn btn-primary mt-2" onClick={e => {
                                        setShowCourseForm(true);
                                        dispatch({
                                            type: 'RESET_ALL',
                                            payload: {
                                                center: 'Jaipur',
                                                course: 'CSIR NET/JRF',
                                                subject: 'Computer Science',
                                                program: 'Regular Course'
                                            }
                                        })
                                    }}>Add Program Interests</button>
                                    </Link>&nbsp;&nbsp;
                                    <Link to="/dashboard/user-profile/address-form">
                                    <button className="mt-2 btn btn-primary" onClick={e => {
                                        setShowAddressForm(true);
                                        dispatch({
                                            type: 'RESET_ALL',
                                            payload: {}
                                        })
                                    }}>Add Address Details</button>
                                    </Link>
                                </div> */}
                            </div>: <h5 className="p-3">Unable to fetch profile details. Please try again after sometime...</h5>
                        }
                    </div>
                </div>
                <Switch>
                    <Route path= {props.match.path} exact render={props => <EditProfile {...props} profile={userDetails}/>}></Route>
                    <Route path='/dashboard/user-profile/course-form'>
                        <div className="card-header d-flex align-items-center" style={{backgroundColor: '#ffffff', borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}}>
                            <h3>Add Course Interests</h3>
                        </div>
                        <form className="form-group-course p-4" name="course-form" onSubmit={e => submitAddressOrCourse(e)}>
                            <div className="form-group px-2">
                                <label for="center">Center</label>
                                <select id="inputState" name="center"  className="form-control"  required onChange={e=>dispatch({
                                    type: 'center',
                                    payload: e.target.value
                                })}>
                                    <option className="p-2 inline-block"selected>Jaipur</option>
                                </select>
                            </div>
                            <div className="form-group px-2">
                                <label for="course">Course</label>
                                <select id="course"  name="course" className="form-control" required onChange={e=>dispatch({
                                    type: 'course',
                                    payload: e.target.value
                                })}>
                                    <option selected>CSIR NET/JRF</option>
                                    <option>UGC NTA NET/JRF</option>
                                    <option>IIT JAM</option>
                                    <option>MSc Entrance</option>
                                    <option>PhD Entrance</option>
                                    <option>CSIR NET/JRF</option>
                                </select>
                            </div>
                            <div className="form-group px-2">
                                <label for="subject">Subject</label>
                                <select id="subject"  name="subject" className="form-control"  required onChange={e=>dispatch({
                                    type: 'subject',
                                    payload: e.target.value
                                })}>
                                    <option selected>Physical Science</option>
                                    <option>Computer Science</option>
                                </select>
                            </div>
                            <div className="form-group px-2">
                                <label for="program">Program</label>
                                <select id="program"  name="program" className="form-control" required onChange={e=>dispatch({
                                    type: 'program',
                                    payload: e.target.value
                                })}>
                                    <option selected>Regular Course</option>
                                    <option>Weekend Course</option>
                                </select>
                            </div>
                            <div className="px-2 mb-2">
                                <button className="btn btn-success px-2">Save</button>&nbsp;&nbsp;
                                <Link to="/dashboard/user-profile">
                                    <button type="button" className="btn btn-primary" onClick={_ => setShowCourseForm(false)}>Back</button>
                                </Link>
                            </div>
                        </form> 
                    </Route>
                    <Route path="/dashboard/user-profile/address-form">
                        <div className="card-header d-flex align-items-center" style={{backgroundColor: '#ffffff', borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}}>
                            <h3>Add Address</h3>
                        </div>
                        <form className="form-group-address p-4" name="address-form" onSubmit={e => submitAddressOrCourse(e)}>
                            <div className="form-group px-2">
                                <label for="inputState">State</label>
                                <select id="inputState" name="inputState" className="form-control" onChange={e=>dispatch({
                                    type: 'state',
                                    payload: e.target.value
                                })} required>
                                    <option value="">Select State</option>
                                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                    <option value="Assam">Assam</option>
                                    <option value="Bihar">Bihar</option>
                                    <option value="Chandigarh">Chandigarh</option>
                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                    <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                                    <option value="Daman and Diu">Daman and Diu</option>
                                    <option value="Delhi">Delhi</option>
                                    <option value="Goa">Goa</option>
                                    <option value="Gujarat">Gujarat</option>
                                    <option value="Haryana">Haryana</option>
                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                    <option value="Jharkhand">Jharkhand</option>
                                    <option value="Karnataka">Karnataka</option>
                                    <option value="Kerala">Kerala</option>
                                    <option value="Lakshadweep">Lakshadweep</option>
                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                    <option value="Maharashtra">Maharashtra</option>
                                    <option value="Manipur">Manipur</option>
                                    <option value="Meghalaya">Meghalaya</option>
                                    <option value="Mizoram">Mizoram</option>
                                    <option value="Nagaland">Nagaland</option>
                                    <option value="Orissa">Orissa</option>
                                    <option value="Pondicherry">Pondicherry</option>
                                    <option value="Punjab">Punjab</option>
                                    <option value="Rajasthan">Rajasthan</option>
                                    <option value="Sikkim">Sikkim</option>
                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                    <option value="Tripura">Tripura</option>
                                    <option value="Uttaranchal">Uttaranchal</option>
                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                    <option value="West Bengal">West Bengal</option>
                                </select>
                            </div>
                            <div className="form-group px-2">
                                <label for="city-name">City</label>
                                <input type="text"  name="city" className="form-control" id="city-name" placeholder="City Name"  onChange={e=>dispatch({
                                    type: 'city',
                                    payload: e.target.value
                                })} required></input>
                            </div>
                            <div className="form-group px-2">
                                <label for="pin-code">Pin Code</label>
                                <input type="text" name="pin"  className="form-control" id="pin-code"  onChange={e=>dispatch({
                                    type: 'pinCode',
                                    payload: e.target.value
                                })} minLength="6" placeholder="Pin Code"maxLength="6" required ></input>
                            </div>
                            <div className="form-group px-2">
                                <label for="address"  name="address" >Address</label>
                                <textarea className="form-control" id="address"  onChange={e=>dispatch({
                                    type: 'address',
                                    payload: e.target.value
                                })} rows="3" placeholder="Enter Address"required minLength="10"></textarea>
                            </div>
                            <div className="px-2 mb-2">
                                <button className="btn btn-success px-2">Save</button>&nbsp;&nbsp;
                                <Link to="/dashboard/user-profile">
                                    <button type="button" className="btn btn-primary"  onClick={_ => setShowAddressForm(false)}>Back</button>
                                </Link>
                            </div>
                        </form>
                    </Route>
                </Switch> 
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userData
    }
}

export default connect(mapStateToProps)(UserProfile);
