import React from 'react';
import './css/testimonials.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import test1 from '../assets/images/test1.jpg'
import test2 from '../assets/images/test2.jpeg'
import test3 from '../assets/images/test3.jpeg'
import test4 from '../assets/images/test4.jpeg'
import test5 from '../assets/images/test5.jpg'

export default function TestimonialsComponent() {
    return (
    <>
    <div className="container pt-5">
        <div className="section-title aos-init aos-animate" data-aos="fade-up">
            <p className="text-primary">Hear What Our Students Say</p>
        </div>
    </div>
    <section id="testimonials" className="testimonials">
        <div className="container">

            <div className="owl-carousel testimonials-carousel owl-loaded owl-drag aos-init aos-animate" data-aos="zoom-in">
                <div className="owl-stage-outer">
                    <div className="owl-stage">
                    <OwlCarousel
                        loop
                        margin={10}
                        nav
                        items="1"
                        autoplay = {true}
                        autoplayTimeout = {5000}
                        autoplayHoverPause = {true}
                    >
                            <div className="testimonial-item">
                                <img src={test1} className="testimonial-img" alt="" />
                                <h3>Anjali Gupta</h3>
                                <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    GAMMA is best coaching for us.. this platform give me a Lots of knowledge...I never knew that maths will be so much fun to learn and you just made it possible. Thanku for the energy you put into taught found it's way into our brains somehow, someway. it was sometimes easy  but when it was hard,, you rarely allowed it show ...I'm  am very greatful to have Gamma maths academy.
                                    One of the best coaching institute !
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                 t               </p>
                            </div>
                            <div className="testimonial-item">
                                <img src={test2} className="testimonial-img" alt="" />
                                <h3>Nikita Sharma</h3>
                                <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                        Maths - problematic......Are you serious?
                                        Join Gamma Maths Academy under Gopi sir's guidance .....they don't just let you feel so in future anymore and transform Maths -- interesting +enhancement of logical ideas and beauty of Maths.
                                        Don't think....do give it a try! 
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                </p>
                            </div>
                            <div className="testimonial-item">
                                <img src={test3} className="testimonial-img" alt="" />
                                <h3>Manisha Yadav</h3>
                                <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    The GAMMA MATHS ACADEMY is more then best. It  it is the best NET  JAM Institute over across Rajasthan. the way of teaching of Gopi sir is mind blowing and the facilities are superb. I am very lucky to connected with Gamma maths Academy and GOPI sir....
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                </p>
                            </div>
                            <div className="testimonial-item">
                                <img src={test4} className="testimonial-img" alt="" />
                                <h3>Dharmendra</h3>
                                <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    Best institute for higher mathematics & teacher's behaviour as a friendly & like as family member .. GAMMA MATHS ACADEMY IS BEST INSTITUTE FOR ALL EXAMS RELATED TO MATHS..
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                </p>
                            </div>
                            <div className="testimonial-item">
                                <img src={test5} className="testimonial-img" alt="" />
                                <h3>Ajay Yadav</h3>
                                <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    Best institute for higher mathematics... experienced faculty... motivated environment...all in one 
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                </p>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    )
}