import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import SpinnerComponent from './common/SpinnerComponent';
import { connect } from 'react-redux';
import Axios from 'axios';
import ErrorComponent from './common/ErrorComponent';
import { setCourseData } from '../redux/actions';
import './css/multi-carousel.scss';
import OwlCarousel from 'react-owl-carousel';

function CoursesCarousel(props) {
    const history = useHistory();
    const [fetchingCourses, setfetchingCourses] = useState(true);
    const [hasError, setShowError] = useState(false);
    const {coursesData, setAllCourses} = props;

    useEffect(() => {
        async function checkCourses() 
        {
            if(!coursesData) {
                try {
                    const res = await Axios.get('/courses');
                    setAllCourses(res.data.courses.filter(course => course.active === true));
                    setfetchingCourses(false);
                } catch(error) {
                    setShowError(true);
                    setfetchingCourses(false);
                }
            } else {
                setfetchingCourses(false)
            }
        }
        checkCourses();
    }, [coursesData, setAllCourses]);

    function getCourses() {
        const courseList = [];
        coursesData.forEach((course, index) => {
            courseList.push(
                <div className="px-2 display-flex pb-3" style={{height: '100%'}}key={course.courseId}>
                    <div className="card sp-card carousel-card" onClick={(e)=>{
                        history.push('/courses/'+ course.courseId);
                    }}
                    style={{height: '100%', border: '1px solid rgba(0,0,0,0.1)'}}
                    >
                        <img src={course.image} className="img-fluid mx-auto d-block" alt="img1" style ={{width: '100%', maxHeight: '175px', minHeight: '175px', borderBottom: '1px solid rgba(0,0,0,0.1)'}} />
                        <div className="card-body justify-content-start">
                            <h4 style={{minHeight: '45px'}}><strong>{course.title}</strong></h4>
                            <p className="mb-0" style={{fontSize: '0.8rem', fontWeight: '600'}}>{course.description}</p>
                        </div>
                    </div>
                </div>
            )
        });
        return courseList;
    }
    
    const options = {
        loop: false,
        margin:10,
        nav:true,
        dots: false,
        responsive:{
            1139:{
                items:4
            },
            720:{
                items:3
            },
            570:{
                items:2
            },
            0:{
                items:1
            }
        }
    };
    
    return (
        <section id="courses-carousel">
            <div className="container">
                {
                    <div className="top-content carousel-courses" style={{marginLeft: '-1rem'}}>
                    <div className="container-fluid multi-carousel">
                            <div className="section-title aos-init aos-animate pb-2 pl-2" data-aos="fade-up">
                                <p className="text-primary">Our Courses</p>
                            </div>
                            <div id="carousel-example-slider" style={{position: "relative", minHeight: '80px'}}>
                            {
                                !fetchingCourses?
                                hasError? <div className="pl-4"><ErrorComponent message="Unable to fetch courses. Please try again!"/></div>:
                                <OwlCarousel 
                                    {...options}
                                >
                                    {getCourses()}
                                </OwlCarousel>
                                : <SpinnerComponent />
                            }
                            </div>
                    </div>
                </div>
                }
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        coursesData: state.courseData
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setAllCourses: (courseData) => dispatch(setCourseData(courseData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesCarousel);
