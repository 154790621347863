import React, { useEffect, useState } from 'react'
import './css/live-class.scss'
import jQuery from 'jquery';

export default function LiveClass(props) {
    const [dateObj, setDateObj]= useState({});
    useEffect(() => {
        (function($) {
                var $chatbox = $('.chatbox'),
                    $chatboxTitle = $('.chatbox__title'),
                    $chatboxTitleClose = $('.chatbox__title__close');
                $chatboxTitle.on('click', function() {
                    $chatbox.toggleClass('chatbox--tray');
                });
                $chatboxTitleClose.on('click', function(e) {
                    e.stopPropagation();
                    $chatbox.addClass('chatbox--closed');
                });
                $chatbox.on('transitionend', function() {
                    if ($chatbox.hasClass('chatbox--closed')) $chatbox.remove();
                });
        })(jQuery);
        const {startTime , endTime }= props.liveClass;
        var currTime = new Date();
        var startTimeDate = new Date();
        var endTimeDate = new Date();
        const period1 = startTime.substring(5,7);
        const period2 = endTime.substring(5,7);
        const hour1 = startTime.substring(0,2);
        const hour2 = endTime.substring(0,2);
        const min1 = startTime.substring(3,5);
        const min2 = endTime.substring(3,5);
        console.log(new Date(props.liveClass.date))
        startTimeDate.setHours(period1 === 'AM'? +hour1 === 12? 0: +hour1: +hour1 === 12? +hour1: +hour1 + 12, min1, 0);
        endTimeDate.setHours(period2 === 'AM'?  +hour2 === 12? 0: +hour2:  +hour2 === 12? +hour2: +hour2 + 12, min2, 0);

        setDateObj({
            currTime: currTime,
            startTime: new Date(startTimeDate - 15 * 60000),
            endTime: endTimeDate,
            liveClassForToday: new Date().setHours(0,0,0,0) === new Date(props.liveClass.date).setHours(0,0,0,0)
        });
        return () => {
            (function($) {
                var $chatbox = $('.chatbox'),
                    $chatboxTitle = $('.chatbox__title'),
                    $chatboxTitleClose = $('.chatbox__title__close');
                    
                    $chatboxTitle.off('click');
                    $chatboxTitleClose.off('click');
                    $chatbox.off('transitionend');
            })(jQuery);
        }
    }, []);

    function moveToLivePlayer() {
        props.history.push(props.location.pathname + '/live-lecture', {
            videoUrl: props.liveClass.videoUrl,
            chatUrl: props.liveClass.chatUrl
        });
    }
    return (
        <div className="chatbox chatbox--tray chatbox--empty">
            <div className="chatbox__title bg-default">
                <h5><span className="text-white">Live Class</span></h5>
                <button className="chatbox__title__tray">
                    <span></span>
                </button>
                <button className="chatbox__title__close">
                    <span>
                        <svg viewBox="0 0 12 12" width="12px" height="12px">
                            <line stroke="#FFFFFF" x1="11.75" y1="0.25" x2="0.25" y2="11.75"></line>
                            <line stroke="#FFFFFF" x1="11.75" y1="11.75" x2="0.25" y2="0.25"></line>
                        </svg>
                    </span>
                </button>
            </div>
            <div className="chatbox__credentials bg-white">
                <p>Date: <span className="text-success">{props.liveClass.date}</span></p>
                <p>Timings: <span className="text-success">{props.liveClass.startTime} - {props.liveClass.endTime}</span></p>
                <p>{props.liveClass.description}</p>
                {
                    dateObj.liveClassForToday && dateObj.currTime >= dateObj.startTime && dateObj.currTime < dateObj.endTime && <button type="submit" className="btn btn-primary btn-block" onClick={ _ => moveToLivePlayer()}>Join Class</button>
                }
            </div>
            <textarea className="chatbox__message" placeholder="Write something interesting"></textarea>
        </div>
    )
}
