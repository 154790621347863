import React, { Component } from 'react'
import Axios from 'axios'
import SpinnerComponent from '../common/SpinnerComponent';
import { connect } from 'react-redux';
import { setCourseData } from '../../redux/actions';
import ErrorComponent from '../common/ErrorComponent';
import DataTable from 'react-data-table-component';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import StudentInfo from './StudentInfo';

class ViewStudents extends Component {
    constructor(props) {
        super(props);

        this.studentNameRef = React.createRef();

        this.state = {
            fetchingDetails: true,
            hasError: false,
            courses: null,
            courseSelected: null,
            students: null,
            studentApiResponse: null,
            selectedUser: null
        }
    };

    async componentDidMount() {
        try {
            if (!this.props.coursesData) {
                const res = await Axios.get('/courses');
                this.setState(prevState => {
                    return {
                        ...prevState,
                        courses: res.data.courses,
                    }
                });
                this.props.setCourseData(res.data.courses);
                this.fetchAllUsers();
            } else {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        fetchingDetails: false,
                        hasError: false,
                        courses: this.props.coursesData,
                    }
                });
            }
        } catch (error) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    fetchingDetails: false,
                    hasError: true,
                }
            });
        }

        this.checkUserId();
    }

    async fetchAllUsers() {

        try {
            const res = await Axios.get('/users');
            this.setState(prevState => {
                return {
                    ...prevState,
                    fetchingDetails: false,
                    hasError: false,
                    students: res.data.users,
                    studentApiResponse: res.data.users
                }
            });
        } catch (error) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    fetchingDetails: false,
                    hasError: true,
                }
            });
        }
    }

    checkUserId() {
        const path = this.props.location.pathname;
        let id = Number(path.substring(path.lastIndexOf('/') + 1, path.length));
        if(!isNaN(id)) {
            id = id + '';
            const studentInfo = this.state.students.find(student => student.userId === id);
            if(studentInfo) {
                this.showInfoFor(studentInfo);
            } else {
                this.props.history.replace('/admin/students');
            }
        }
    }

    async componentDidUpdate(prevProps, previousState) {
        if (this.state.courseSelected && this.state.courseSelected.courseId) {
            const courseId = this.state.courseSelected.courseId;
            if (!previousState.courseSelected || previousState.courseSelected.courseId !== courseId) {
                this.setState(prevState => {
                    return {
                        ...this.state,
                        fetchingDetails: true,
                    }
                });
                // try {
                //     const res = await Axios.get('/users');
                //     this.setState(prevState => {
                //         return {
                //             ...prevState,
                //             fetchingDetails: false,
                //             hasError: false,
                //             students: res.data.users,
                //             studentApiResponse: res.data.users
                //         }
                //     });
                // } catch (error) {
                //     this.setState(prevState => {
                //         return {
                //             ...prevState,
                //             fetchingDetails: false,
                //             hasError: true,
                //         }
                //     });
                // }
            }
        }
    }

    async setCourse(course) {
        // if (course.courseId !== (this.state.courseSelected && this.state.courseSelected.courseId)) {
            // const course = Http call
        this.studentNameRef.current.value = ''
        this.setState(prevState => {
            return {
                ...prevState,
                hasError: false,
                courseSelected: course
            }
        });
        if(course.courseId !== 'All') {
            try {
                const res = await Axios.get('/users?courseId='+course.courseId);
                this.setState(prevState => ({
                    ...prevState,
                    fetchingDetails: false,
                    students: res.data.users,
                    studentApiResponse: res.data.users
                }))
            } catch (err) {
                this.setState(prevState => ({
                    ...prevState,
                    hasError: true,
                    fetchingDetails: false,
                }))
            }
        } else {
           this.fetchAllUsers();
        }
        // }
    }
    
    showInfoFor(row) {
        this.setState(prevState => {
            return {
                ...prevState,
                selectedUser: {
                        userInfo: this.state.students.find(s => s.userId === row.userId),
                        coursesInfo: this.state.courses
                    }
            }
        });
        this.props.history.push("/admin/students/" + row.userId);
    }
    async resetDevId(row){
        try{
            await Axios.post('/user/reset-device',{
                userId: row.userId,
            });
            alert('Successfully reset')
        }catch(err){
            alert('Failed to reset')
        }
        
    }

    updateStudent(id, data) {
        this.setState(prevState => {
            return {
                ...prevState,
                students: prevState.students.map(student => student.userId === id? data: student)
            }
        })
    }
    columns = [
        {
            name: 'Username',
            selector: 'username',
            sortable: true
        },
        {
            name: 'Mobile Number',
            selector: 'phone',
            sortable: true
        },
        {
            name: 'Email Address',
            sortable: true,
            cell: (row) => <span title={row.email}>{row.email}</span>
        },
        {
            name: 'Number of Courses',
            cell: (row) => <p className="mx-5">{row.courses.length + (row.courseHistory?.length || 0)}</p>,
        },
        {
            name: 'Actions',
            cell: (row) => <button className="btn btn-success" onClick={(event)=>this.showInfoFor(row)}>View Courses</button>
        },
        {
            name: 'Reset Device',
            cell: (row) => <button className="fa fa-sync btn" onClick={(event)=>this.resetDevId(row)}></button>
        }
    ];

    filterStudents() {
        this.setState(prevState => {
            return {
                ...prevState,
                students: prevState.studentApiResponse.filter(student => {
                    console.log('student', student, student.username.toLowerCase().includes(this.studentNameRef.current.value.toLowerCase()))
                    return student.username.toLowerCase().includes(this.studentNameRef.current.value.toLowerCase())}
                )
            }
        })
    }

    render() {
        return (
            <div style={{ position: "relative", height: '100%' }}>
            <Switch>
                {this.state.selectedUser && <Route path={this.props.match.path + '/:studentId'} render={(props) => <StudentInfo selectedUser={this.state.selectedUser} updateStudent={(id, data) =>this.updateStudent(id, data)} {...props}/>} />}
            <Route path={this.props.match.path}>
                <>
                {this.state.fetchingDetails ? <SpinnerComponent opacity="0.5" /> : ''}
                {this.state.hasError ? <ErrorComponent /> : ''}
                {
                    this.state.courses && this.state.courses.length ?
                        <div className="btn-group dropright">
                            <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Filter By Course
                            </button>
                            <div className="dropdown-menu">
                            {
                                [{courseId: 'All', title: 'All Courses'}].concat(this.state.courses).map(course => (<span className="dropdown-item" onClick={(e) => this.setCourse(course)} key={course.courseId}>{course.title}</span>))
                            }
                            </div>
                        </div>
                    : ''
                }
                {
                    
                    this.state.students &&
                    <div className="mt-5">
                        <div className="form-group">        
                            <input type="text" ref={this.studentNameRef} className="form-control" style={{maxWidth: '200px', display: 'inline'}} placeholder="Enter Student Name"></input>&nbsp;&nbsp;
                            <button type="button" onClick={() => this.filterStudents()}className="btn btn-primary">Search</button>
                        </div>
                        <DataTable
                            title={this.state.courseSelected ? this.state.courseSelected.title: 'All Students'}
                            columns={this.columns}
                            data={this.state.students}
                            keyField="_id"
                            pagination = {true}
                            paginationPerPage = {7}
                            paginationRowsPerPageOptions = {[5, 7, 10, 15, 20, 50, 100]}
                        />
                    </div>
                } 
            </>
            </Route>
            </Switch>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        coursesData: state.coursesData
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCourseData: (courseData) => dispatch(setCourseData(courseData))
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewStudents))