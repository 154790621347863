import React, { useState, useEffect } from 'react'
import './css/course-details.scss';
import $ from 'jquery';
import { calculatePrcnt, formatDate } from '../utils/util';
import { Link, useRouteMatch, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import BuyCourseModal from './BuyCourseModal';
import videoThumb from '../assets/images/videoThumb.png';
import Axios from 'axios';
import ErrorComponent from './common/ErrorComponent';
import SpinnerComponent from './common/SpinnerComponent';
import { connect } from 'react-redux';
import VideoPlayer from './common/VideoPlayer';
import LiveClass from './LiveClass';
import LiveVideoPlayer from './common/LiveVideoPlayer';

function CourseDetailPageComponent(props) {
    const [courseDetails, setCourseDetails] = useState(null);
    const {url, path, params: {id}} = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const [fetchingCourses, setfetchingCourses] = useState(true);
    const [hasError, setShowError] = useState(false);
    const {userData, courses} = props;
    const [hideBuyButton, setHideBuyButton] = useState(false);
    const [courseIsExpired, setcourseIsExpired] = useState(null);
    const [showExpiryModal, setshowExpiryModal] = useState(false);
    const [userHasCourse, setUserHasCourse] = useState(false);
    useEffect(() => {
        async function checkCourses() 
        {
                try {
                    const url = (userData && userData.courses && userData.courses.length) ? userData.courses.find(course => course.courseId === id)? '/user/course/': '/course/': '/course/';
                    setHideBuyButton( url === '/user/course/' ? true: false);
                    const res = await Axios.get(url + id);
                    const course = (courses && courses.find(course => course.courseId === id)) || {};
                    let res2 = {};
                    if(url === '/user/course/' && Object.keys(course).length === 0) {
                        const res3 = await Axios.get('/course/'+id);
                        res2 = res3.data.courses;
                    }
                    url === '/user/course/' && setUserHasCourse(true);
                    setCourseDetails({
                        ...course,
                        ...res2,
                        ...res.data.courses,
                        subjects: url === '/user/course/'? res.data.subjects: res.data.courses.subjects,
                        liveDetails: url === '/user/course/'? res.data.liveDetails: null
                    });
                    const courseExp = userData?.courseHistory?.find(course => course.courseId === id);
                    if(courseExp) {
                        setcourseIsExpired(courseExp);
                        if(!location.pathname.includes('buy-now')) {
                            setshowExpiryModal(true);
                        }
                    }
                    setfetchingCourses(false);
                } catch(error) {
                    console.log(error)
                    setShowError(true);
                    setfetchingCourses(false);
                }
        }
        checkCourses();
    }, [id, userData, courses]);

    useEffect(()=> {
        $(function () {
            function toggleOpen(e) {
                $(e.target)
                    .prev('.card-header')
                    .find(".fa")
                    .removeClass('fa-plus-circle');
                $(e.target)
                    .prev('.card-header')
                    .find(".fa")
                    .addClass('fa-minus-circle');
            }
            function toggleClose(e) {
                $(e.target)
                    .prev('.card-header')
                    .find(".fa")
                    .removeClass('fa-minus-circle');
                $(e.target)
                    .prev('.card-header')
                    .find(".fa")
                    .addClass('fa-plus-circle');
            }
            $('.panel-group').on('hidden.bs.collapse', toggleClose);
            $('.panel-group').on('shown.bs.collapse', toggleOpen);
        });        
        return () => {
            $('.panel-group').off('hidden.bs.collapse');
            $('.panel-group').off('shown.bs.collapse');
        }
    }, [courseDetails]);

    function getSubjectCards() {
        const cards = [];
        courseDetails.subjects.filter(subject => subject.active).forEach((subject, index)  => {
            const subjectName = subject.title.split(" ").join("");
            cards.push(
                <div className="card" style={{borderRadius: '0 !important'}} key={subject.title + index}>
                    <div className="card-header" id={"subheading" + index}>
                        <h5 className="mb-0 d-flex">
                            <button className="btn btn-link collapsed accordion-btn" disabled={!hideBuyButton} style={{textTransform: 'capitalize', display: 'flex','wordBreak': 'break-word', alignItems: 'center'}} data-toggle="collapse" data-target={"#subcollapse"+index} aria-expanded="false" aria-controls={"subcollapse"+index}>
                                <i className={"fa " + (!hideBuyButton? 'fa-lock': 'fa-plus-circle')+  " text-primary fa-lg"} aria-hidden="true"></i><span className="ml-2 inline-block text-left">{subject.title}</span>
                            </button>
                            <p className="ml-auto text-success" style={{alignSelf: 'center', minWidth: '65px', fontSize: '12px', margin: 'auto 0 auto auto'}}>Videos: {Array.isArray(subject.videos)? subject.videos.length: subject.videos}</p>
                        </h5>
                    </div>
                    <div id={"subcollapse"+index} className="collapse" aria-labelledby={"subheading"+index} data-parent="#accordion2">
                        <div className="card-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id={"video-"+subjectName+"-tab"} data-toggle="tab" href={"#video-"+subjectName} role="tab" aria-controls={"video-"+subjectName} aria-selected="true">Videos{subject.videos && !Array.isArray(subject.videos) ? subject.videos.length ? <span>&nbsp;(&nbsp;{subject.videos.length}&nbsp;)</span>: <span>&nbsp;(&nbsp;{subject.videos}&nbsp;)</span>: ''}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id={"assignment-"+subjectName+"-tab"} data-toggle="tab" href={"#assignment-"+subjectName} role="tab" aria-controls={"assignment-"+subjectName} aria-selected="false">Assignments{subject.assignments && !Array.isArray(subject.assignments) ? subject.assignments.length? <span>&nbsp;(&nbsp;{subject.assignments.length}&nbsp;)</span>: <span>&nbsp;(&nbsp;{subject.assignments}&nbsp;)</span>: ''}</a>
                        </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id={"video-"+subjectName} role="tabpanel" aria-labelledby={"video-"+subjectName+"-tab"}>
                            <div className="card video-cards-container br-t-0">
                            {
                                subject.videos && subject.videos.length && checkNonActive(subject.videos)? 
                                <>
                                <div className="row justify-content-center p-3 pb-5 videos-container">
                                    {getVideoCards(subject)}
                                </div>
                                </>:  hideBuyButton || subject.videos === 0 || checkNonActive(subject.videos)? <p className="p-3 mb-0">Sorry! No videos available at the moment. Please try again later.</p>
                                :  <p className="p-3 mb-0">Sorry! No videos available for you at the moment. Please buy the course to view Videos.</p>
                                }
                            </div>
                        </div>
                        <div className="tab-pane fade" id={"assignment-"+subjectName} role="tabpanel" aria-labelledby={"assignment-"+subjectName+"-tab"}>
                        <div className="card video-cards-container br-t-0">
                            {
                                ((subject.assignments && subject.assignments.length) && checkNonActive(subject.assignments))? 
                                <>
                                <div className="row justify-content-center p-3 pb-5">
                                    {getAssignmentsCards(subject)}
                                </div>
                                </>
                                :  hideBuyButton || subject.assignments === 0 || checkNonActive(subject.assignments)? <p className="p-3 mb-0">Sorry! No assignments available at the moment. Please try again later.</p>
                                :  <p className="p-3 mb-0">Sorry! No Assignments available for you at the moment. Please buy the course to view Assignments.</p>
                                }
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            )
        })
        return cards;
    }
    
    function checkNonActive(value) {
        return value && value.length && !value.every(val => val.active === false);
    }

    // function downloadAssignment(assignment) {
    //     if(assignment.url && assignment.title) {
    //         const link = document.createElement('a');
    //         link.download = assignment.title;
    //         link.href = assignment.url;
    //         link.click();
    //     }
    // }

    function getAssignmentsCards(subject) {
        const cards = [];
        subject.assignments.forEach((assignment, index) => {
            assignment.active && cards.push(
            <div className=" col-12 col-sm-6 col-md-4 col-lg-3 card-wrapper mt-2 d-flex" key={assignment.title + index}>
                <div className="card internal-card ml-2 mt-2 course-assignment-card">
                    <div className="card-body d-flex flex-column">
                        <p><strong>{assignment.title}</strong></p>
                        <a href={assignment.url} target="_blank" rel="noopener noreferrer" className="btn btn-primary" style={{backgroundColor: 'background-color: #FF0039'}}>View</a>
                    </div>  
                </div>
            </div>
            )
        });
        return cards;
    }

    function handleClickOnVideo(subject, index) {
        const video = subject.videos[index];
        if(video.url || video.videoId) {
            history.push(url + '/video', {
                url: video.url,
                courseId: courseDetails.courseId
            })
        } else {
            history.push(url+'/buy-now');
        }
    }

    function getVideoCards(subject) {
        const cards = [];
        subject.videos.forEach((video, index) => {
            video.active && cards.push(
            <div className=" col-12 col-sm-6 col-md-4 col-lg-3 card-wrapper mt-2 d-flex" key={video.title + index}>
                <div className="card internal-card ml-2 mt-2 course-video-card" style={{cursor: 'pointer'}} onClick={() => handleClickOnVideo(subject, index)}>
                    <div className="card-image-top">
                        <img className="w-100 img-width-100" src={videoThumb} alt={video.title}/>
                    </div>
                    <div className="card-body d-flex flex-column">
                        <p className="mb-1"><strong>{video.title}</strong></p>
                        <p className="video-description">{video.description}</p>
                    </div>
                </div>
            </div>
            )
        });
        return cards;
    }

    return (
    <Switch>
    {
        props.isLoggedIn && !courseIsExpired && <Route exact path={path + "/video"} component={VideoPlayer}></Route>
    }
    {
        props.isLoggedIn && !courseIsExpired && <Route exact path={path + "/live-lecture"} component={LiveVideoPlayer}></Route>
    }
        <Route path = {path}>
        {
            fetchingCourses? 
            <div className="loading" style={{position: 'relative'}}>
                <SpinnerComponent/>
            </div>: 
            hasError? 
            <div className="error-container p-4" style={{position: 'relative'}}>
                <ErrorComponent message="Unable to load course details. Please try again with valid Course Details."/>
            </div>:
            <div className="course-details-page" >
                {
                    showExpiryModal && 
                    <div className="buy-course-modal">
                        <div className="modal fade show" style={{display: 'block'}}id="modal-notification" tabIndex="-1" role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
                        <div className="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                            <div className="modal-content bg-gradient-danger">
                                
                                <div className="modal-header">
                                    <h6 className="modal-title" id="modal-title-notification">Course Renew is required</h6>
                                </div>
                                
                                <div className="modal-body">
                                    
                                    <div className="py-3 text-center">
                                        <i className="ni ni-bell-55 ni-3x"></i>
                                        <h4 className="heading mt-4">{courseIsExpired.message}</h4>
                                        <p className="text-white">Pending Amount: ₹{courseIsExpired.amount}</p>
                                    </div>
                                    
                                </div>
                                
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-white" onClick={e => setshowExpiryModal(false)}>Ok, Got it</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className="d-flex flex-column" style={{background: '#505757', color: 'white'}}>
                    <div className="custom-row" style={{position: 'relative', padding: '3rem 0'}}>
                        <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                            <div className="bg-gradient-primary" style={{height: '50%'}}></div>
                            <div className="separator separator-bottom separator-skew" style={{position: 'absolute', top: '50%', left: 0, right: 0, bottom: '50%', transform: 'translateY(-99%)'}}>
                                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
                                </svg>
                            </div>
                            <div className="bg-default" style={{height: '50%'}}></div>
                        </div>
                        <div className="container row mx-auto p-5" style={{backgroundColor: '#f9f9f9', borderRadius: '12px', zIndex: 1, position: 'relative'}}>
                        <div className="col-md-6">
                            <div className="course-details md-visible">
                                <h1 className="pb-1" style={{fontSize: '2rem', fontWeight: '600'}}>{courseDetails.title}</h1>
                                {courseDetails.description ? <h3>{courseDetails.description}</h3>: ''}
                                <br/>
                                <span className="d-flex align-items-center">
                                    <i className={"fa fa-play-circle text-primary"}></i>&nbsp;&nbsp;
                                    <span><strong style={{fontWeight: '600'}}>Batch Start Date: </strong>
                                    {formatDate(courseDetails.startDate)}</span>
                                </span>
                                <span className="d-flex align-items-center mt-2">
                                    <i className="fa fa-stop-circle text-danger"></i>&nbsp;&nbsp;
                                    <span><strong style={{fontWeight: '600'}}>Batch End Date: </strong>
                                    {formatDate(courseDetails.endDate)}</span>
                                </span>
                                <div className="mt-4">
                                    <Link to={url+"/buy-now"}>
                                    {!hideBuyButton? <button className="btn btn-primary mx-auto">{courseIsExpired? 'Renew': (courseDetails.price == 0 || courseDetails.discount == 0) ? 'Enroll Now': 'Buy Now'}</button>: ''}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
                            <div className="card w-100" style={{maxWidth: '375px', marginBottom: 0, borderRadius: '5px', boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.05)'}}>
                                <img className="w-100" style={{height: '250px', maxHeight: '250px', borderRadius: '5px'}} src={courseDetails.image} alt=""></img>
                                <div className="course-details sm-visible">
                                    <h1 className="pb-1" style={{fontSize: '2rem', fontWeight: '600'}}>{courseDetails.title}</h1>
                                    {courseDetails.description ? <h3>{courseDetails.description}</h3>: ''}
                                    <br/>
                                    <span className="d-flex align-items-center">
                                        <i className="fa fa-play-circle text-primary"></i>&nbsp;&nbsp;
                                        <span><strong style={{fontWeight: '600'}}>Batch Start Date: </strong>
                                        {formatDate(courseDetails.startDate)}</span>
                                    </span>
                                    <span className="d-flex align-items-center mt-2">
                                        <i className="fa fa-stop-circle text-danger"></i>&nbsp;&nbsp;
                                        <span><strong style={{fontWeight: '600'}}>Batch End Date: </strong>
                                        {formatDate(courseDetails.endDate)}</span>
                                    </span>
                                </div>
                                {
                                    !hideBuyButton?                                    
                                    <h2 className="text-center p-3 mb-0">
                                    {   
                                        !courseIsExpired? 
                                        (courseDetails.discount || courseDetails.discount === 0) && courseDetails.discount !== courseDetails.price 
                                        ? <span style={{fontSize: '20px'}}>
                                            ₹{courseDetails.discount}&nbsp;<strike style={{fontSize: '16px'}}>₹{courseDetails.price}</strike>&nbsp;<span style={{fontSize: '16px'}}>(-{calculatePrcnt(courseDetails.price, courseDetails.discount)}%)</span>
                                            </span>
                                        : <span style={{fontSize: '20px'}}>₹{courseDetails.price}</span>
                                        :<span style={{fontSize: '20px'}}>₹{courseIsExpired.amount}</span>
                                    }</h2> : ''
                                    
                                }
                                <div className="px-5 text-center mt-4 sm-visible  pb-3">
                                    <Link to={url+"/buy-now"}>
                                    {!hideBuyButton? <button className="btn btn-primary mx-auto">{courseIsExpired? 'Renew': (courseDetails.price == 0 || courseDetails.discount == 0) ? 'Enroll Now': 'Buy Now'}</button>: ''}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-4">
                    <div className="row">
                        { (courseDetails.subjects && courseDetails.subjects.length === 0) || courseDetails.subjects.every(subject => subject.active === false) ? <h3 className="text-center pb-3" style={{flexBasis: '100%'}}>Subjects will be available soon!</h3>: ''}
                        {/* <div className="col-sm-12 col-lg-3 courses-nav-pills-container">
                            <NavigationPills navItems={courseDetails.subjects.filter(subject => subject.active).map(subject => subject.title)} />
                        </div>
                        <div className="col-sm-12 col-lg-9 mb-5">
                        <div className="tab-content subject-details-tab" id="v-pills-tabContent">
                            {getNavTabs()}
                        </div>
                        </div> */}
                        <div className="container">
                            <div className="panel-group" id="accordion2">
                                {getSubjectCards()}
                            </div>
                        </div>
                    </div>
                    {userHasCourse && !courseIsExpired && courseDetails.liveDetails && <Route render={props => <LiveClass {...props} liveClass={courseDetails.liveDetails}/>}></Route>}
                </div>
                <Switch>
                    <Route path={path+'/buy-now'} render={(props) => <BuyCourseModal {...props} courseDetails={courseDetails}/>}/>
                </Switch>
            </div>
            }
            </Route>
        </Switch>
    )
}
const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        courses: state.courseData,
        userCoursesFetched: state.userCoursesFetched,
        isLoggedIn: state.logInState
    }
}
export default connect(mapStateToProps)(CourseDetailPageComponent)
