import React from 'react';
import ElearnVector from '../assets/images/online-learning-vector.png'

export default function WhyGamma() {
    return (
    <section id="details" className="details">
        <div className="container pt-5">
            <div className="section-title aos-init aos-animate" data-aos="fade-up">
                <p className="text-primary">Why join Gamma Maths Academy</p>
            </div>
        </div>
      <div className="container">
        <div className="row content">
          <div className="col-md-4 aos-init aos-animate" data-aos="fade-right">
            <img src={ElearnVector} className="img-fluid" style={{height: '100%', maxHeight: '320px'}} alt=""/>
          </div>
          <div className="col-md-8 pt-2 aos-init aos-animate" data-aos="fade-up">
            <ul style={{listStyleType: 'none'}}>
              <li><i className="fas fa-check text-success"></i>Daily 4 hours Live Class</li>
              <li><i className="fas fa-check text-success"></i>Daily Doubt Session ( 2 hours extra )</li>
              <li><i className="fas fa-check text-success"></i>Online  test series</li>
              <li><i className="fas fa-check text-success"></i>Top Ranker's in CSIR - NET in Rajasthan from Gamma</li>
              <li><i className="fas fa-check text-success"></i>Solution of more than 5000 questions in Doubt Session</li>
              <li><i className="fas fa-check text-success"></i>Topic wise and Book wise Test</li>
              <li><i className="fas fa-check text-success"></i>More than 5 full length test based on CSIR-NET</li>
              <li><i className="fas fa-check text-success"></i>Hand Notes for quick revision</li>
              <li><i className="fas fa-check text-success"></i>All in care of NET experts</li>
              <li><i className="fas fa-check text-success"></i>Reasonable fee structure</li>
              <br/>
              <li>For more information, Contact :- <br/>
                Gamma Maths Academy
                Main Tonk Phatak, Jaipur <br/>
                7665120570
                8385924444
            </li>
            </ul>
            <p>

            </p>
          </div>
        </div>
    </div>
    </section>
    )
}
