import React from 'react'

export default function StudentsPerformance(props) {
    function getImages() {
        const images = [];
        for (let i = 1; i <= 5; i++) {
            if(i===3 || i===1 || i===5){
                const src = process.env.PUBLIC_URL + '/students/' + i + '.jpeg';
                images.push(
                    <div className="col-12 col-md-4 p-2 student-performance" key={i}>
                        <div className="gallery-item aos-init aos-animate" data-aos="zoom-in" style={{height: '100%'}}data-aos-delay="450">
                            <span onClick={e=> {props.history.push('/image/performance/' + i + '.jpeg')}} className="venobox vbox-item" data-gall="gallery-item">
                                <img src={src} style={{height: '150px'}} className="img-fluid w-100" alt=""/>
                            </span>
                        </div>
                    </div>
                )

            }
        }
        // setImages(img);
        return images;
    }
    return (
        <section id="students" className="team">
            <div className="container">
                <div className="section-title aos-init aos-animate" data-aos="fade-up">
                    <p className="text-primary">Outstanding Performances</p>
                </div>
                <div className="row no-gutters aos-init aos-animate" data-aos="fade-left">
                    {getImages()}
                </div>
                <div className="row no-gutters aos-init aos-animate justify-content-center" data-aos="fade-left">
                    <button onClick={e=> {props.history.push('/image/performance/1.jpeg')}}  className="btn btn-outline-primary mt-2">View More</button>
                </div>

            </div>
        </section>
    )
}
