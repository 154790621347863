import React from 'react';
export function checkPhoneNumber(value, elementId) {
    console.log(value,/^((\+){0,1}91(\s){0,1})?([6-9]\d{9})$/.test(value))
    if(/^((\+){0,1}91(\s){0,1})?([6-9]\d{9})$/.test(value)) {
        return true;
    }
    const el = document.getElementById(elementId);
    el.setCustomValidity('Please enter a valid Phone number');
    el.reportValidity();
    return false;
}

export function checkPasswords(value1, value2, passwordElementId ) {
    if(value1 === value2){ return true} else {
        const el = document.getElementById(passwordElementId);
        el.setCustomValidity('Provided passwords did not match');
        el.reportValidity();
        return false;
    }
}


export function getCoursesCards(courses, callback, hidePrice, cardDisabled) {
    const courseList = [];
    courses.forEach((course, index) => {
        courseList.push(
            <div className={"card-content-container col-12 col-md-6 col-lg-6 d-flex " + (cardDisabled && !course.active ? ' card-disabled': '')} onClick={() => callback(course.courseId)} key={course.courseId}>
                <div className="card course-card d-flex w-100" style={{maxHeight: '200px'}} >
                    <img src={course.image} className="img-fluid" alt="img1" style ={{ minWidth: '200px',maxWidth: '200px', maxHeight: '100%', minHeight: '150px', 
                    'borderTopRightRadius': '0px', 'borderBottomRightRadius': '0px', 'borderBottomLeftRadius': '12px'}} />
                    <div className="card-body p-4">
                        <div>
                            <h2 style ={{ color: 'black'}}><strong>{course.title}</strong></h2>
                        </div>
                        <div className="card-desc mb-2">
                            <p className="card-text" style ={{ fontSize: '0.8rem', fontWeight: '700', color: '#858079'}}>{course.description}</p>
                            {cardDisabled && !course.active &&<p className="card-text text-danger" style ={{ fontSize: '0.8rem'}}>Course Expired</p>}
                        </div>
                        {!hidePrice && <span className="text-success" style={{display: 'inline-block', marginTop: 'auto', fontWeight: '600'}}><i className="fa fa-rupee-sign" aria-hidden="true"></i>&nbsp;&nbsp;{course.discount || course.price}</span>}
                    </div>
                </div>
            </div>
        )
    });
    return courseList;
}

export function calculatePrcnt(itemPrice, discountedPrice) {
    return (((itemPrice-discountedPrice)/itemPrice) * 100).toFixed(0);
}

export const MM = ["January", "February","March","April","May","June","July","August","September","October","November", "December"];
export function formatDate(date) {
    if(date){ 
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = MM[newDate.getMonth()];
        const day = ('0' + newDate.getDate()).slice(-2);
        return `${day} ${month} ${year}`;
    }; 
}