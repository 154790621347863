import { SET_LOGGED_IN, SET_ACCOUNT_TYPE, SET_LOGIN_ACTION, SET_USER_DATA, SET_COURSE_DATA, SET_USER_COURSES } from "./constants"

export const setLoggedIn = (isLoggedIn) => {
    return {
        type: SET_LOGGED_IN,
        payload: isLoggedIn
    }
};

export const setAccountType = (accType) => {
    return {
        type: SET_ACCOUNT_TYPE,
        payload: accType
    }
};
export const setActionAfterLogIn = (actionType, courseId) => {
    return {
        type: SET_LOGIN_ACTION,
        actionType: actionType,
        payload: courseId
    }
};

export const setUserData = (data) => {
    return {
        type: SET_USER_DATA,
        payload: data
    }
};

export const setCourseData = (data) => {
    return {
        type: SET_COURSE_DATA,
        payload: data
    }
};

export const setUserCourses = (courseIsSet) => {
    return {
        type: SET_USER_COURSES,
        payload: courseIsSet
    }
};