import React, { useEffect, useState } from 'react';
import './css/modal.scss';
import { setActionAfterLogIn } from '../redux/actions';
import { Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { REDIRECT_TO } from '../redux/constants';
import  upi_image  from '../assets/images/upi-qr-code.jpeg';
import Axios from 'axios';

function BuyCourseModal(props) {
    const {isLoggedIn, setPostRegistrationState, location: {pathname}, userData, courseDetails} = props;
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccess, setShowSuccess]= useState(false);
    const [showError, setShowError]= useState(false);
    const [expiredCourse, setexpiredCourse] = useState(null);
    const history = useHistory();
    const price = courseDetails.discount == 0 ? 0 : (courseDetails.discount  || courseDetails.price);

    useEffect(() => {
        !isLoggedIn ? setPostRegistrationState(pathname)  : setPostRegistrationState('')
        if(userData && userData.courses.find(course => course === courseDetails.courseId || course.courseId === courseDetails.courseId)){
            history.replace('/courses/'+courseDetails.courseId);
        }
        const courseExp = userData && userData.courseHistory?.find(course => course.courseId === courseDetails.courseId);
        if(courseExp) {
            setexpiredCourse(courseExp);
        }
    }, [isLoggedIn, setPostRegistrationState, pathname, courseDetails, userData, history]);

    async function sendOrderReuqest(e) {
        e.preventDefault();
        if(!showSuccess) {
            setShowLoader(true);
            const file = document.getElementById('receipt-pic')?.files[0];
            const reader = new FileReader();
            if(file) {
                reader.onload = async function(e){
                    try {
                        await Axios.post('/course/order',{
                            courseId: props.courseDetails.courseId,
                            userId: props.userData.userId,
                            receipt: e.target.result,
                            amount: expiredCourse?.amount || price,
                            email: props.userData.email,
                            state: expiredCourse? 'renew': 'pending'
                        });
                        setShowError(false);
                        setShowSuccess(true);
                        setShowLoader(false);
                        const receipt = document.querySelector('.alert-receipt');
                        receipt && receipt.focus();
                    } catch(error) {
                        setShowLoader(false);
                        setShowError(true);
                        const receipt = document.querySelector('.alert-receipt');
                        receipt && receipt.focus();
                        
                    }
                }
                reader.readAsDataURL(file);
            } else {
                try {
                    await Axios.post('/course/order',{
                        courseId: props.courseDetails.courseId,
                        userId: props.userData.userId,
                        amount: 0,
                        email: props.userData.email,
                    });
                    setShowError(false);
                    setShowLoader(false);
                    window.location.reload();
                } catch(error) {
                    setShowLoader(false);
                    setShowError(true);
                }
            }
        } else {
            history.push('/dashboard');
        }
    }
    return (
        <>
        {
            !isLoggedIn ? <Redirect to={{
                pathname: "/user/login",
                message: "Please login in order to buy the course."
            }}></Redirect> :
            <div className="buy-course-modal">
            <div className="modal-custom" id="buy-now-modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{position: 'relative'}}>
                    <div className="modal-header">
                        <h5 className="modal-title">{!expiredCourse?'Buy Course': 'Renew Course'} {props.courseDetails.title}</h5>
                    </div>
                    <div className="modal-body d-flex flex-column justify-content-center align-items-center" >
                    {showSuccess? 
                        <p className="alert alert-success alert-receipt" tabIndex="-1">
                           { price != 0? 'We have successfully raised your request to buy the course. We will validate and approve it. ' +
                            'If it doesn\'t get approved in some time. Please contact us. Thankyou!'
                            :   'Your request for enrolling the course was successfully approved. Please refresh the page to see new content'
                        }
                        </p>: ''}
                    {showError? 
                        <p className="alert alert-danger alert-receipt" tabIndex="-1">
                            We are unable to process your request at the moment. Please try again after sometime. 
                            If the problem persists please try compressing the image size or contact us.
                        </p>: ''}
                    {showLoader?
                        <div className="text-center position-absolute d-flex flex-column justify-content-center align-items-center" style={{backgroundColor: 'rgba(255,255,255,0.85)', top: 0, left: 0, right: 0, bottom: 0,zIndex: '20001'}}>
                            <div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <h5 className="mt-2">Uploading details!<br/> Please wait. Do not close or refresh the window.</h5>
                        </div>: ''
                    }

                    {    
                        price == 0? 
                        <p>
                            Please confirm your enrollment to the course by pressing Confirm
                        </p>
                    :   
                        <>    
                        <img src={upi_image} style={{maxWidth: '300px', maxHeight: '300px'}} alt="UPI ID QR"></img>
                        <p>{'In Order to ' + (!expiredCourse? 'buy': 'renew') +' course '+ props.courseDetails.title +' take the following steps:'}</p>
                        <ul>
                            <li>Scan the above QR and Make a payment of ₹{!expiredCourse? props.courseDetails.discount || props.courseDetails.price: expiredCourse.amount} to {(!expiredCourse? 'buy': 'renew')} the course</li>
                            <li>Send the following details to phone number 8385924444 on whatsapp
                                <ul>
                                    <li>Course name: {props.courseDetails.title}</li>  
                                    <li>Phone Number used to make payment</li>  
                                    <li>Your Name</li>  
                                    <li>Email Id</li> 
                                </ul> 
                            </li>
                            <li>After sending the details take a screenshot</li>
                        </ul>
                        Upload the screenshot here and press Confirm<br/>
                        </>
                    }
                    <form className="d-flex flex-column align-items-center" onSubmit = {e => sendOrderReuqest(e)}>
                        { price != 0 && <input type="file" id="receipt-pic" name="screenshot-image" accept="image/*" className="mt-2" required></input>}<br/>
                        <div className="btn-group">
                            <button type="button" className="btn btn-secondary m-2" onClick={e => props.history.push(props.location.pathname.substring(0, props.location.pathname.lastIndexOf('/')))}>Cancel</button>
                            <button type="submit" className="btn btn-primary m-2" data-dismiss="modal" >{!showSuccess?'Confirm': 'Home'}</button>
                        </div>
                    </form>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.logInState,
        userData: state.userData
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setPostRegistrationState: (courseId) => dispatch(setActionAfterLogIn(REDIRECT_TO, courseId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyCourseModal);
