import React, { useState } from "react";
import { connect } from "react-redux";
import { setLoggedIn, setAccountType, setUserData, setUserCourses } from "../redux/actions";
import { ADMIN, USER } from "../redux/constants";
import axios from 'axios';
import { Link, useLocation, useHistory } from "react-router-dom";
import SpinnerComponent from "./common/SpinnerComponent";

function LogInForm(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    const [showVerifyScreen, setShowVerifyScreen] = useState(false);
    const [hasError, setErrorState] = useState(false);
    const location  = useLocation();
    const [isLoading, setLoading] = useState(false);
    const [verifyError, setVerifyError] = useState('');
    let [userId, setUserId] = useState('');

    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        try {
         const res = await axios.post('/user/login', {
             email: username,
             password: password
         });
         const obj = res.data.userDetails || res.data.userObj;
         if(obj.role === 'user') {
            const res2 =await axios.get('/user/courses');
            props.setUserData({
                ...obj,
                courses: res2.data.filter(course => obj.courses.includes(course.courseId)),
                courseHistory: res2.data.filter(course => !!obj.courseHistory?.find(c => c.courseId === course.courseId)).map(course => {
                    return {
                        ...course,
                        ...obj.courseHistory.find(c => c.courseId === course.courseId)
                    }
                }),
            });
            props.userDataFetched(true);
         } else {
            props.setUserData(obj);
         }
         props.setAccountType(obj.role === 'admin'? ADMIN: USER);
         props.logIn(true);
         if(obj.role === 'user' && props.afterLogInAction) {
            history.push(props.afterLogInAction.payload);
         } 
        } catch(error) {
            setLoading(false);
                if(error.response && error.response.status === 403) {
                    setShowVerifyScreen(true);
                    setUserId(error.response.data.userId);
                } else {
                    setErrorState(true);
                }
            }
        } 

    async function sendVerifyApiRequest() {
        setLoading(true);
        try {
            await axios.post('/user/otp',{
                userId: userId,
            });
            setLoading(false);
            setVerifyError('');
            history.push('/user/verifyNow/'+ userId);
        } catch(error) {
            setLoading(false);
            setVerifyError('Unable to create a new request. Please try again.')
        }
    }

    return (
        <>
        {   
            !showVerifyScreen?

            (
            <>
            <form className="form-signin" onSubmit={(event) => handleSubmit(event)}>

                    {isLoading? <SpinnerComponent/>: ''}
                <small className="d-flex justify-content-center">Login to your account</small><br></br>
                    {(location.state && location.state.message)? <p className="text-success-container">{location.state.message}<br/></p>: ''}
                    {location.message? <p className= "text-danger-container">{location.message}<br/></p>: ''}
                <div className="form-label-group">
                    {/* <label htmlFor="inputEmail" className="mt-2">Email Address</label> */}
                    <div className="input-icons"> 
                        <i className="fa fa-envelope"></i> 
                        <input className="input-field form-control mainLoginInput admin-username" type="email" id="inputEmail" 
                        placeholder="Email"
                        required onChange={(event) => setUsername(event.target.value)}/> 
                    </div> 
                    {/* <label htmlFor="inputEmail" className="mt-2">Email Address</label>
                    <input type="email" id="inputEmail" className="form-control mainLoginInput admin-username" 
                        placeholder="fa fa-bath" required
                        onChange={(event) => setUsername(event.target.value)}
                    /> */}
                </div>
                <div className="form-label-group">
                <div className="input-icons"> 
                        <i className="fa fa-unlock-alt"></i> 
                        <input className="input-field form-control"  
                        placeholder="Password"
                        type="password" id="inputPassword" required
                        onChange={(event) => setPassword(event.target.value)}/> 
                </div> 
                    {hasError? <p className="text-danger mt-2" style={{marginBottom: '-0.7rem', }}>Invalid Username/ Password</p>: ''}
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-lg btn-primary m-2 rounded" type="submit">Log In</button>
                    </div>
                    {/* <div className="text-center mt-3">
                        <Link to="/user/forgot-password"><span>Forgot password?</span><br/></Link>
                        <span className="d-inline-block mt-2">Don't have an account <Link to="/user/register"><span>Sign Up here !</span></Link></span>
                    </div> */}
                </div>
            </form>
            <div className="mt-3 d-flex justify-content-between">
                <Link to="/user/forgot-password"><small>Forgot password?</small><br/></Link>
                <span className="d-inline-block mt-2"><Link to="/user/register"><small>Create new account</small></Link></span>
            </div>
            </>
        ): 
            <div>
                {isLoading? <SpinnerComponent/>: ''}
                {verifyError? <p className="text-danger">{verifyError}</p>: ''}
                <h5 className="mt-3">Your account is not verified. </h5>
                <p>Click on button below to receive an OTP to verify your account.</p>
                <button className="btn btn-primary btn-block" onClick={_ => sendVerifyApiRequest()}>Verify Now</button>
            </div>
        }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.logInState,
        accountType: state.accountType,
        afterLogInAction: state.afterLogInAction
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logIn: (logInState) => dispatch(setLoggedIn(logInState)),
        setAccountType: (accType) => dispatch(setAccountType(accType)),
        setUserData: (data) => dispatch(setUserData(data)),
        userDataFetched: (fetched) => dispatch(setUserCourses(fetched))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogInForm)