import React, { useState, createRef } from 'react'
import Axios from 'axios';
import SpinnerComponent from './common/SpinnerComponent';

export default function VerifyOtp(props) {
    const [showLoader, setShowLoader] = useState(false);
    const optRef = createRef();
    const [showOtpError, setShowOtpError] = useState('');

    async function verifyOtp(e) {
        e.preventDefault();
        setShowLoader(true);
        try {
            await Axios.post('/user/otp/verify', {
                userId: props.match.params.userId,
                otp: optRef.current.value
            });
            props.history.push('/user/login', {
            message: 'Email Verified Successfully! Please login to continue.'
            });
        } catch(error) {
            setShowOtpError(true);
        }
        setShowLoader(false);
    }

    return (
        <form name="otp-form" onSubmit={(e) => verifyOtp(e)}>
          {showLoader? <SpinnerComponent/> : ''}
          <div className="form-label-group">
            <label htmlFor="otp-field">We have sent an OTP to the provided Email. Please check the inbox and enter the OTP below:</label>
            <div className="input-icons"> 
            <i className="fa fa-key"></i> 
            <input
              type="text"
              id="otp-field"
              className="form-control mt-2"
              placeholder="Enter OTP"
              required
              ref={optRef}
              minLength="6"
              maxLength="6"/> 
              <small className="form-text text-muted">Please note OTP is valid for 5 minutes only.</small>
            </div>
          </div>
          {showOtpError? <p className="text-danger" style={{marginBottom: '-0.7rem'}}>Unable to verify OTP. Please enter a valid OTP.</p>: ''}
          <button className="btn btn-lg btn-primary btn-block mt-4" type="submit">Verify OTP</button>
        </form>
    )
}
